import diff from 'simple-text-diff'

export const limitWords = (text, limit) => {
  if (text && text.length >= limit) {
    while (text[limit] !== ' ') limit--
    text = text.substring(0, limit + 1)
  }
  return text
}

export const getDiff = (oldText, newText) => {
  return diff.diffPatch(oldText, newText).after
}

export const getTextDifferenceObjects = (oldText, newText) => {
  const difference = diff.diffPatch(oldText, newText).after
  const diffObjects = []
  let startIndex = 0,
    stopIndex = 0
  while (startIndex !== -1) {
    startIndex = difference.indexOf('<ins>', startIndex === 0 ? 0 : startIndex + 5)
    stopIndex = difference.indexOf('</ins>', stopIndex === 0 ? 0 : stopIndex + 6)
    if (startIndex === -1) break
    const object = {
      startIndex,
      text: difference.substring(startIndex + 5, stopIndex),
    }
    diffObjects.push(object)
  }
  return diffObjects
}
