<template>
  <div :class="{ hidden: !documentOpened }" class="text-window open-document">
    <div @click="close()" class="cancel-icon">
      <unicon name="times" width="3em" height="3em" />
      <!-- <p>[esc]</p> -->
    </div>
    <div class="full-text">
      <div>
        <p>
          V první fázi procesu vzniku jsme společně psali do jednoho google dokumentu, vy zde dostanete stejnou
          příležitost. Pište, cokoliv vás napadlo, reagujte na to, co už někdo napsal.
          <span>Nikdy ale prosím nic nemažte a nepřepisujte</span>, ale můžete např. něco dopsat do závorek.<br /><br />
        </p>
      </div>
      <div ref="textarea" contenteditable="true" @keydown="keypress" class="writable-textarea" @input="type"></div>
      <!-- <h1>{{ textWindow.name }}</h1>
      <p>{{ textWindow.text }}</p> -->
    </div>
  </div>
</template>

<script>
import { io } from 'socket.io-client'
import { getDiff } from '../utils/text'

export default {
  emits: ['close'],
  props: ['documentOpened'],
  data() {
    return {
      socketClient: null,
      text: '',
      originalText: '',
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    type(e) {
      const textarea = e.target
      textarea.style.height = `${textarea.scrollHeight}px`
      this.socketClient.emit('type', textarea.innerText)
      this.text = textarea.innerText
      getDiff(this.originalText, this.text)
      // const sel = document.getSelection()
      // sel.focusOffset
      // while (html.indexOf('<ins>') !== -1) {
      //   html = html.replace('<ins>', '<span>').replace('</ins>', '</span>')
      // }
      // if (this.$refs.textarea) this.$refs.textarea.innerHTML = html
      // sel.extend(sel.focusNode, offset)
    },
    // keypress(e) {
    //   if (e.key === 'Backspace') {
    //     e.preventDefault()
    //     const edits = getTextDifferenceObjects(this.originalText, this.text)
    //     console.log(edits)
    //   }
    // },
  },
  mounted() {
    this.socketClient = io('/document')
    this.socketClient.on('receive_type', text => {
      if (!this.originalText) this.originalText = text
      this.text = text
      if (this.$refs.textarea) this.$refs.textarea.innerHTML = text
    })
  },
}
</script>

<style lang="scss">
.open-document {
  .full-text {
    margin: 0;
    span {
      color: red;
    }
  }
  .writable-textarea {
    border: 1px dashed black;
    background-color: white;
    padding: 0.5em;
    -webkit-user-select: text;
    user-select: text;
    min-height: 100%;
    min-width: 95%;
    resize: none;
    outline: none;
    font-size: 1em;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    overflow-y: hidden !important;
  }
  .writable-textarea:focus {
    border: 1px dashed rgba(0, 0, 0, 0.18);
  }

  //   .full-text {
  //     min-height: 200vh;
  //   }
}
</style>
