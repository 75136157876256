<template>
  <svg
    @mouseenter="fill = hoverFill"
    @mouseleave="fill = 'none'"
    :width="width"
    :height="height"
    id="svg1872"
    sodipodi:version="0.32"
    inkscape:version="0.44.1"
    sodipodi:docbase="E:\Bryan\Temp"
    sodipodi:docname="Alchemy air symbol.svg"
    viewBox="0 0 60 50"
  >
    <defs id="defs1874" />
    <g id="layer1" inkscape:label="Layer 1" inkscape:groupmode="layer">
      <g id="g2858" transform="matrix(1,0,0,-1,0,64)">
        <path
          transform="matrix(-0.640633,-0.767847,0.767847,-0.640633,26.59089,56.10602)"
          d="M 31.545456,33.363636 L 15.64208,52.416194 L 7.0937687,29.117188 L -1.4545428,5.8181818 L 22.997144,10.06463 L 47.448831,14.311078 L 31.545456,33.363636 z "
          inkscape:randomized="0"
          inkscape:rounded="0"
          inkscape:flatsided="false"
          sodipodi:arg2="1.7427482"
          sodipodi:arg1="0.69555062"
          sodipodi:r2="28.65699"
          sodipodi:r1="14.328495"
          sodipodi:cy="24.181818"
          sodipodi:cx="20.545456"
          sodipodi:sides="3"
          id="path1880"
          :style="{ fill, stroke }"
          style="
            fill-opacity: 1;
            fill-rule: evenodd;
            stroke-width: 3;
            stroke-linecap: round;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
          sodipodi:type="star"
        />
        <path
          sodipodi:nodetypes="cc"
          :style="{ stroke }"
          id="path1902"
          d="M 9.4999998,35.272727 L 54.5,35.272727"
          style="
            fill: none;
            fill-rule: evenodd;
            stroke-width: 3;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'air',
  props: ['width', 'height', 'hoverFill', 'stroke'],
  data() {
    return {
      fill: 'none',
    }
  },
}
</script>

<style lang="scss">
path {
  transition: fill 1s;
}
</style>
