import { createApp } from 'vue'
import App from './App.vue'
import Unicon from 'vue-unicons'
import {
  uniVolumeUp,
  uniPause,
  uniPlay,
  uniTimes,
  uniCompressAltLeft,
  uniSpinner,
  uniThumbsUp,
  uniArrowUpRight,
  uniVolumeMute,
  uniExpandArrowsAlt,
} from 'vue-unicons/dist/icons'
import router from './router'
import store from './store'

Unicon.add([
  uniPlay,
  uniTimes,
  uniPause,
  uniVolumeUp,
  uniVolumeMute,
  uniCompressAltLeft,
  uniSpinner,
  uniThumbsUp,
  uniArrowUpRight,
  uniExpandArrowsAlt,
])

const app = createApp(App).use(store).use(router)
app.use(Unicon)

app.mount('#app')
