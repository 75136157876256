<template>
  <div class="player">
    <div v-if="player.mediaType === 'audio'" class="audio">
      <audio autoplay ref="audio" preload="metadata">
        <source :src="player.link" type="audio/mpeg" />
      </audio>
    </div>
    <div v-if="player.mediaType === 'video'" :class="{ 'in-front': player.inFront }" class="video">
      <video autoplay :width="1100" :height="700" ref="video" preload="metadata">
        <source :src="player.link" type="video/mp4" />
      </video>
      <div @click="videoOpen()" v-if="!player.inFront" class="open-video-button">
        <unicon width="5.5em" height="5.5em" name="arrow-up-right" />
      </div>
      <!-- <div @click="videoOpen()" v-if="player.inFront" class="fullscreen-video-button">
        <unicon width="2em" height="2em" class="volume" name="expand-arrows-alt" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Player',
  data() {
    return {
      media: '',
      fullscreenEnabled: false,
    }
  },
  emits: ['video-open-click'],
  computed: {
    ...mapState(['player', 'isMobile']),
  },
  methods: {
    ...mapMutations([
      'setPlayerTime',
      'setPlayerDuration',
      'togglePlay',
      'setCanPlay',
      'togglePlayerLoading',
      'setPlayerLoaded',
    ]),
    videoOpen() {
      this.$emit('video-open-click')
    },
    requestFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
      }
    },
  },

  created() {
    this.media = this.player.mediaType
    this.$store.watch(
      state => state.player.fullscreen,
      () => {
        if (this.media === 'video' && this.$refs[this.media]) {
          if (this.fullscreenEnabled) {
            this.requestFullscreen(this.$refs[this.media])
            this.$refs[this.media].style.pointerEvents = 'initial'
          }
        } else {
          this.$refs[this.media].style.pointerEvents = 'none'
        }
      }
    )
    this.$store.watch(
      state => state.player.playing,
      () => {
        if (this.$refs[this.media]) {
          if (!this.player.playing) {
            this.$refs[this.media].pause()
          } else this.$refs[this.media].play()
        }
      }
    )
    this.$store.watch(
      state => state.player.seekTo,
      () => {
        if (this.$refs[this.media] && this.player.seekTo) {
          this.$refs[this.media].currentTime = this.player.seekTo
          this.player.seekTo = 0
        }
      }
    )
    this.$store.watch(
      state => state.player.volume,
      () => {
        if (this.$refs[this.media]) {
          this.$refs[this.media].volume = this.player.volume
        }
      }
    )

    this.fullscreenEnabled =
      document.fullscreenEnabled || document.mozFullScreenEnabled || document.documentElement.webkitRequestFullScreen
  },
  mounted() {
    const media = this.media
    this.$refs[media].addEventListener('loadedmetadata', () => {
      this.setPlayerDuration(this.$refs[media].duration)
      this.$refs[media].volume = this.player.volume
      setInterval(() => {
        if (this.$refs[media] && !this.$refs[media].paused) {
          this.setPlayerTime(this.$refs[media].currentTime)
          const bufferedRangesLength = this.$refs[media].buffered.length
          this.setPlayerLoaded(this.$refs[media].buffered.end(bufferedRangesLength - 1))
        }
      }, 500)
    })

    this.$refs[media].addEventListener('waiting', () => {
      this.togglePlayerLoading(true)
    })
    this.$refs[media].addEventListener('canplay', () => {
      this.setCanPlay(true)
      //this.togglePlay();
      this.togglePlayerLoading(false)
    })

    //check if html player triggered by anything else (e.g. keyboard play button)
    const cmds = ['play', 'pause']
    cmds.forEach(cmd => {
      this.$refs[media].addEventListener(cmd, () => {
        if ((!this.player.playing && cmd === 'play') || (this.player.playing && cmd === 'pause')) {
          this.togglePlay()
        }
      })
    })
  },
}
</script>

<style lang="scss">
.player {
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  .video {
    position: relative;
    width: fit-content;
    transform: scale(0.6) translateZ(0em);
    &.in-front {
      transform: scale(1) translateZ(1em);
    }
    .fullscreen-video-button,
    .open-video-button {
      fill: var(--text-color);
      pointer-events: initial;
      position: absolute;
      top: 5em;
      right: 2em;
      background-color: black;
      border-radius: 0em;
      &:hover {
        background-color: var(--text-color);
      }
      .unicon {
        margin-top:0.2em;
      }
    }
    // .fullscreen-video-button {
    //   top: initial;
    //   bottom: 5em !important;
    // }
  }
  // .video {
  //   transition: transform 2s !important;
  // }
}

@media only screen and (max-device-width: 812px) {
  .video {
    display: none;
    &.in-front {
      display: initial;
    }
  }
}
</style>
