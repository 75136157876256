<template>
  <div :class="{ hidden: !textWindow.isOpen }" class="text-window">
    <div @click="close()" class="cancel-icon">
      <unicon name="times" width="3em" height="3em" />
      <!-- <p>[esc]</p> -->
    </div>
    <div class="full-text">
      <h1>{{ textWindow.name }}</h1>
      <p>
        <span v-for="(textObject, index) in formatText" :key="index" class="text generated-link"
          >{{ textObject.text }}
          <a class="generated-link" :href="`https://` + textObject.link" target="_blank">{{ textObject.link }}</a></span
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['textWindow'],
  emits: ['close'],
  data() {
    return {
      isOpen: false,
      text: '',
      name: '',
    }
  },
  computed: {
    formatText() {
      const text = this.textWindow.text
      let textObjectArray = []
      let lastIndex = 0
      for (;;) {
        let index = text.indexOf('https://', lastIndex + text.slice(index).split(' ')[0].length)
        if (index == -1) {
          textObjectArray.push({
            text: text.slice(lastIndex != 0 ? lastIndex + text.slice(lastIndex).split(' ')[0].length : 0),
            index,
          })
          break
        }
        textObjectArray.push({
          text: text.slice(lastIndex != 0 ? lastIndex + text.slice(lastIndex).split(' ')[0].length : 0, index),
          link: text.slice(index).split(' ')[0].split('https://')[1],
        })
        lastIndex = index
      }
      return textObjectArray
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.text-window {
  position: absolute;
  opacity: 1;
  width: 100vw;
  height: 100vh;
  top: 0em;
  left: 0;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateZ(2em) translateY(0em);
  background-color: rgba(0, 0, 0, 0.928);
  transition: transform 0.5s;
  .cancel-icon {
    position: absolute;
    // background-color: black;
    // padding: 0.4em;
    margin-bottom: 0.3em;
    animation: forwards 1s appear-opacity;
    transition: opacity 1s;
    top: 2em;
    right: 2em;
    svg {
      fill: var(--text-color);
      transform: translateY(0.1em);
      width: 4em;
      height: 4em;
    }
    p {
      font-size: 0.9em;
      margin: 0.3em 0;
      color: white;
      width: 100%;
      text-align: center;
      font-family: 'Roboto', sans-serif;
    }
    &:hover {
      background-color: var(--text-color);
    }
  }
  &.hidden {
    transform: translateZ(2em) translateY(-100em);
    pointer-events: none;
  }
  .full-text {
    padding: 3em 4em;
    margin-top: 2em;
    width: 50%;
    height: 100%;
    overflow-y: scroll;
    font-family: 'Roboto', sans-serif;
    line-height: 1.3em;
    background-color: white;
    h1 {
      text-decoration: underline;
      text-decoration-color: var(--select-color);
      text-underline-offset: 0.1em;
      margin-bottom: 1.5em;
      font-size: 1.7em;
    }
    p {
      font-size: 0.95em;
      line-height: 1.8em;
      span {
        a {
          color: blue;
          text-decoration: none;
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}

@media only screen and (max-device-width: 812px) {
  .text-window {
    overflow-y: hidden !important;
  }
  .full-text {
    padding: 2em !important;
    width: 70% !important;
    //-webkit-overflow-scrolling: touch !important;
    margin-bottom: 7em;
    h1 {
      margin-top: 1.5em;
    }
  }
}
</style>
