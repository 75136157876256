import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Intro from '../views/Intro.vue'
import About from '../views/About.vue'

import axios from 'axios'

//lazy loads
const Admin = () => import('../views/internal/Admin.vue')
const Login = () => import('../views/internal/Login.vue')

const routes = [
  {
    path: '/',
    name: 'Intro',
    component: Intro,
  },
  {
    path: '/:element',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    beforeEnter: async (to, from, next) => {
      const token = sessionStorage.getItem('token')
      if (!token) return next({ name: 'Login' })

      try {
        const tokenHeader = `Bearer ${token}`
        const { data } = await axios.get('/adminapi/auth', { headers: { Authorization: tokenHeader } })
        if (data) next()
      } catch (e) {
        if (e.response.status === 403 || e.response.status === 404) {
          sessionStorage.removeItem('token')
          return next({ name: 'Login' })
        }
      }
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
