<template>
  <div @mouseleave="hideMenu()" :class="{ dark: articleWindow || dark }" class="menu">
    <div class="navigation">
      <p
        @touchstart="toggleAbout()"
        @mouseenter="showMenu()"
        :class="{ dark: articleWindow || dark || $route.name === 'About' || aboutOpened, 'menu-hidden': menuHidden }"
        :style="{ textDecoration: $route.name === 'About' ? 'underline' : 'none' }"
        class="logo"
      >
      WHOMAN
      </p>
      <a ref="back" :class="{ hidden: menuHidden }" class="menu-item" href="/">Zpět na začátek</a>
      <p
        ref="about"
        :class="{ hidden: menuHidden, selected: $route.name === 'About' || aboutOpened }"
        class="menu-item"
        @click.prevent="() => toggleAbout()"
      >
        O projektu
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { limitWords } from '../utils/text'
export default {
  name: 'Menu',
  data() {
    return {
      menuHidden: true,
      menuTimeOut: null,
    }
  },
  props: ['dark'],
  computed: {
    ...mapState(['articleWindow', 'isMobile', 'aboutOpened']),
  },
  methods: {
    ...mapMutations(['setAboutOpened']),
    limitName(name) {
      return limitWords(name, 15)
    },
    showMenu() {
      this.$refs.back.style.position = 'initial'
      this.$refs.about.style.position = 'initial'

      this.menuHidden = false
      if (this.menuTimeOut) {
        clearTimeout(this.menuTimeOut)
        this.menuTimeOut = null
      }
    },
    toggleAbout() {
      this.setAboutOpened(!this.aboutOpened)
    },
    hideMenu() {
      if (this.isMobile) this.menuHidden = true
      else {
        if (this.menuHidden === false) {
          this.menuTimeOut = setTimeout(
            () => {
              this.menuHidden = true
              setTimeout(
                () => {
                  this.$refs.back.style.position = 'absolute'
                  this.$refs.about.style.position = 'absolute'
                },
                500,
                { once: true }
              )
            },
            700,
            { once: true }
          )
        }
      }
    },
  },
}
</script>

<style lang="scss">
.menu {
  position: fixed;
  top: 0.5em;
  left: 2em;
  z-index: 100000;
  transform: translateY(0) translateZ(15em);
  max-width: fit-content;
  .player-control {
    max-width: max-content;
    //background-color: black;
    min-width: 15em;
    display: flex;
    align-items: center;
    padding: 0.3em;
    p {
      font-family: 'Roboto', sans-serif;
      color: black;
      font-size: 0.7em;
    }
    .progress-bar {
      margin: 0.3em;
      position: relative;
      width: 4em;
      height: 0.2em;
      background-color: var(--point-color);
      transform: translateY(-50%);
      .inner-bar {
        position: absolute;
        left: 0;
        width: 2em;
        height: 0.2em;
        background-color: var(--select-color);
      }
    }
    .unicon {
      margin: 0.3em;
      svg {
        fill: black;
      }
    }
  }
  .navigation {
    max-width: fit-content;
    user-select: none;
    max-width: max-content;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    margin-top: 1em;
    // margin-top: 1.2em;
    //   background-color: red;
    display: flex;
    justify-content: left;
    align-items: center;

    .logo {
      margin: 0;
      user-select: none;
      font-size: 1.7em;
      padding: 0.2em;
      background-color: var(--select-color);
      color: var(--select-color);
      text-underline-offset: 0.2em;
      transition: all 0.3s;
      z-index: 10000;
      transform: translateZ(4em) rotate(0deg);
      box-shadow: 0 0 0.3em 0.05em rgba(0, 0, 0, 0.05);
      span {
        font-weight: 400;
        margin: 0 0.05em;
      }
      &.menu-hidden {
        transform: translateZ(4em) rotate(-2deg);
        background-color: transparent;
        color: black;
        box-shadow: none;
        &.dark {
          background-color: black;
          color: white;
          text-decoration: none !important;
        }
      }

      &:hover {
        //text-decoration: underline;
        span {
          opacity: 1;
        }
      }
    }

    &:hover {
      transform: translateY(0) translateZ(4em) !important;
    }
    .menu-item {
      top: -20em;
      position: initial;
      cursor: none;
      user-select: none;
      background-color: white;
      font-weight: 400;
      min-height: 100%;
      padding: 0.54em 1em;
      font-size: 1.2em;
      color: black;
      text-decoration: none;
      text-underline-offset: 0.2em;
      text-decoration-color: var(--select-color);
      transition: transform 0.1s;
      margin: 0;

      &.hidden {
        transform: translateY(-6em) translateZ(4em);
        position: absolute;
      }
      &.selected {
        background-color: var(--text-color);
        transform: translateY(0em) !important;
        color: black;
        box-shadow: 0 0 0.3em 0.05em rgba(0, 0, 0, 0.1);
        &:hover {
          box-shadow: none;
        }
      }
      &:hover {
        //text-decoration: underline;
        color: black;
        background-color: var(--select-color);
      }
      &:nth-of-type(1) {
        transition-delay: 0s;
      }
      &:nth-of-type(2) {
        transition-delay: 0.1s;
      }
    }
  }
  &.dark {
    .menu-item {
      background-color: var(--black);
      color: white;
    }
  }
}

@media only screen and (max-device-width: 812px) {
  .menu {
    font-size: 1em;
    margin-left: 1em;
    //max-width: 70vw;
    .menu-item {
      display: none !important;
      padding: 0 !important;
      margin: 0.5em;
    }
  }
}
</style>
