<template>
  <div class="home">
    <Player @video-open-click="getArticleFullscreen(player.id)" v-if="player.link" />
    <Article
      @close-article="closeArticle()"
      :article="articlePoints[articleFullscreen]"
      v-if="articleFullscreen !== -1"
    />

    <ArticlePreview
      v-if="isMobile && openedArticle"
      :point="articlePoints[openedArticle]"
      :articlePosition="'mobile'"
      :opened="true"
      @point-click="point => pointClick(openedArticle, point)"
      @click="openedArticle = null"
    />
    <AboutPage @fullscreen-image="src => (imgFullscreen = src)" v-if="aboutOpened" />
    <Menu :dark="textWindow.isOpen || openDocumentOpened || imgFullscreen" />
    <OpenDocument :documentOpened="openDocumentOpened" @close="openDocumentOpened = false" />
    <TextWindow :textWindow="textWindow" @close="textWindow.isOpen = false" />
    <FullscreenImage @close-image="imgFullscreen = null" v-if="imgFullscreen" :src="imgFullscreen" />
    <transition>
      <div v-if="!loaded" class="home-loading">
        <unicon :width="'2em'" :height="'2em'" name="spinner" style="animation: spin 3s infinite" />
      </div>
    </transition>
    <transition>
      <div v-if="loaded && intro.enabled" class="intro-text">
        <p v-if="intro.enabled && intro.step == 0">{{ selectedElement.step1Sentence }}</p>
        <p v-else-if="intro.enabled && intro.step >= 1">
          {{ selectedElement.step2Sentence
          }}<span :class="{ red: intro.step > 9 }" v-if="intro.step >= 1" @click="toggleIntro(false)"
            >ukázat vše...
          </span>
        </p>
      </div>
    </transition>
    <template v-if="loaded">
      <div class="points-box">
        <div
          v-for="(point, index) in articlePoints"
          :class="{
            'opacity-one':
              (point.introStepMin <= intro.step && point.elements && point.elements.includes(selectedElement.name)) ||
              (!intro.enabled && !point.intro) ||
              player.id === point.id,
            big: openedArticle === index,
            'big-but-closed': articleFullscreen === index && point.mediaType !== 'text',
            'playing-point': player.id === point.id,
            'checked-article': checkedArticles.includes(point.id),
          }"
          @click="
            e =>
              point.intro && point.id === player.id ? (this.isDrag(e) ? togglePlay() : '') : toggleArticle(e, index)
          "
          :ref="el => setPointRef(el, index)"
          :key="index"
          class="article-point"
          @mouseenter="mouseOverPoint(index, true)"
          @mouseleave="mouseOverPoint(index, false)"
        >
          <div
            :class="{
              top: articlePosition.includes('top') && openedArticle === index,
              bottom: articlePosition.includes('bottom') && openedArticle === index,
              left: articlePosition.includes('left') && openedArticle === index,
            }"
            class="collision-box"
          ></div>
          <div class="collision-box"></div>
          <div class="mark">
            <!-- <heart-icon
              v-if="point.liked"
              :fill="this.selectedElement.colorScheme.selectColor"
              :width="'0.4em'"
              :height="'0.4em'"
            /> -->
            <div v-if="point.liked" :style="{ width: '0.25em', height: '0.25em' }" class="checked-mark">+</div>
            <div
              v-else-if="checkedArticles.includes(point.id)"
              :style="{ width: '.2em', height: '.2em' }"
              class="checked-mark"
            >
              +
            </div>

            <move-icon
              v-else-if="isMobile && !UserMovedWithSomething && player.id !== point.id"
              :fill="this.selectedElement.colorScheme.selectColor"
              :width="'1em'"
              :height="'1em'"
            />
          </div>

          <div v-if="player.id === point.id" :class="{intro:point.intro}" class="playing-alt">
            <p
              @click.stop.prevent="getArticleFullscreen(index)"
              class="alt-name"
              @mouseenter="mouseOverPoint(index, false)"
              @mouseleave="mouseOverPoint(index, true)"
            >
              {{ point.name }}
              <unicon v-if="!point.intro" name="arrow-up-right" width="1em" height="1em" />
            </p>
            <p class="alt-progress-time">
              {{ getPlayerProgressTime.time }}<span>/</span>{{ getPlayerProgressTime.duration }}
            </p>
          </div>
          <div v-if="player.id === point.id" class="play-icon">
            <play-icon :width="'2em'" :height="'2em'" class="play" v-if="!player.playing" />
            <unicon
              :width="'2em'"
              :height="'2em'"
              name="spinner"
              class="spinner"
              style="animation: spin 1s infinite"
              v-else-if="player.isLoading"
            />
            <pause-icon :width="'2em'" :height="'2em'" class="pause" v-else />
          </div>
          <div class="anchor left"></div>
          <div class="anchor"></div>
          <ArticlePreview
            @mouseenter="mouseOverPoint(index, false)"
            v-if="!isMobile"
            :point="point"
            :articlePosition="articlePosition"
            :opened="openedArticle === index"
            @point-click="point => pointClick(index, point)"
            @fullscreen-image="img => (imgFullscreen = img)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
import Article from '../components/Article.vue'
import Player from '../components/Player.vue'
import OpenDocument from '../components/OpenDocument.vue'
import TextWindow from '../components/TextWindow.vue'
import ArticlePreview from '../components/ArticlePreview.vue'
import FullscreenImage from '../components/FullscreenImage.vue'
import AboutPage from '../views/About.vue'

import { getMediaType } from '../utils/mimeFormat'
import { limitWords } from '../utils/text'

import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'

import * as synth from '../utils/synth'

import elements from '../include/elements'

import playIcon from '../svgs/play2.vue'
import pauseIcon from '../svgs/pause.vue'
// import heartIcon from '../svgs/heart.vue'
import moveIcon from '../svgs/move-icon.vue'

import * as Tone from 'tone'

export default {
  name: 'Home',
  data() {
    return {
      articlePoints: [],
      openedArticle: null,
      cursorType: 'default',
      draggedPoint: null,
      lastDragged: null,
      selectedElement: '',
      mouseDownX: 0,
      mouseDownY: 0,
      articleFullscreen: -1,
      articlePosition: '',
      loaded: false,
      checkedArticles: [],
      UserMovedWithSomething: false,
      imgFullscreen: null,
      textWindow: {
        isOpen: false,
        text: '',
        name: '',
      },
      openDocumentOpened: false,
    }
  },
  emits: ['mouse-over-point'],
  components: {
    Article,
    playIcon,
    pauseIcon,
    // heartIcon,
    AboutPage,
    FullscreenImage,
    moveIcon,
    Menu,
    Player,
    OpenDocument,
    TextWindow,
    ArticlePreview,
  },
  computed: {
    ...mapState(['player', 'intro', 'articles', 'articleWindow', 'isMobile', 'aboutOpened']),
    ...mapGetters(['getPlayerProgressTime']),
  },
  methods: {
    ...mapMutations([
      'togglePlay',
      'nextIntroStep',
      'setArticles',
      'toggleIntro',
      'toggleArticleWindow',
      'disableIntro',
      'setElement',
    ]),
    ...mapActions(['getArticles']),
    getArticleFullscreen(index, intro) {
      this.textWindow.isOpen = false
      this.openDocumentOpened = false
      this.imgFullscreen = null
      this.imgFullscreen = null
      this.articleFullscreen = -1
      this.$router.push({ query: { id: this.articlePoints[index]._id } })
      setTimeout(() => {
        //this.togglePlay()
        this.openedArticle = ''
        this.toggleArticleWindow(true)
        this.articleFullscreen = index
        if (intro) {
          this.nextIntroStep()
        }
      }, 1)
    },
    pointClick(index, point) {
      switch (getMediaType(point.mediaType)) {
        case 'image':
          this.nextIntroStep()
          point.liked = true
          break
        case 'text':
          this.openText(point)
          break
        case 'video':
        case 'audio':
          this.getArticleFullscreen(index, point.intro)
          break
        case 'open':
          this.openDocumentOpened = true
          break
      }
    },
    closeArticle() {
      this.articleFullscreen = -1
      this.toggleArticleWindow(false)
    },
    openText(point) {
      this.textWindow.isOpen = Boolean(point)
      this.textWindow.text = point ? point.fullText : ''
      this.textWindow.name = point ? point.name : ''
      this.$router.push({ query: point ? { id: point._id } : {} })
      if (point) {
        window.addEventListener('keydown', this.textEscapeListener, { once: true })
      } else {
        window.removeEventListener('keydown', this.textEscapeListener)
      }
    },
    textEscapeListener(e) {
      if (e.key === 'Escape') {
        this.openText(null)
      }
    },
    limitWords(text, limit) {
      return limitWords(text, limit)
    },
    mouseOverPoint(index, isEnter) {
      this.$emit('mouse-over-point', isEnter)
      if (isEnter && this.openedArticle !== index && !this.draggedPoint) {
        this.playTone('32n')
      }
    },
    mediaType(mimeType) {
      return getMediaType(mimeType)
    },
    getOffset(el) {
      let top = 0,
        left = 0,
        bottom,
        right
      const width = el.offsetWidth
      const height = el.offsetHeight
      while (el) {
        top += el.offsetTop
        left += el.offsetLeft
        el = el.offsetParent
      }

      bottom = top + height
      right = left + width
      return { top, left, bottom, right }
    },
    playTone(length, root = 'C', octave = '4') {
      if (Tone.context.state === 'running') synth.playTone(length, root, octave)
    },
    isDrag(e) {
      const dragX = e.clientX - this.mouseDownX
      const dragY = e.clientY - this.mouseDownY
      return dragX < 2 && dragX > -2 && dragY < 2 && dragY > -2
    },
    applyColorScheme() {
      document.documentElement.style.setProperty('--point-color', this.selectedElement.colorScheme.pointColor)
      document.documentElement.style.setProperty('--select-color', this.selectedElement.colorScheme.selectColor)
      document.documentElement.style.setProperty('--point-border', this.selectedElement.colorScheme.pointBorder)
      document.documentElement.style.setProperty(
        '--text-color',
        this.selectedElement.name === 'earth'
          ? this.selectedElement.colorScheme.textColor
          : this.selectedElement.colorScheme.selectColor
      )
      document.documentElement.style.setProperty(
        '--player-text-color',
        this.selectedElement.name === 'earth' ? 'white' : 'black'
      )
    },
    processArticles() {
      this.applyColorScheme()
      this.articlePoints.forEach((point, index) => {
        const randomLeft = `${Math.floor(Math.random() * (window.innerWidth - 100)) + 10}px`
        const randomTop = `${Math.floor(Math.random() * (window.innerHeight - 100)) + 10}px`
        point.left = randomLeft
        point.top = randomTop
        point.ref.style.left = randomLeft
        point.ref.style.top = randomTop
        const clicks = ['mousedown', 'touchstart']
        clicks.forEach(cmd =>
          point.ref.addEventListener(cmd, e => {
            if (this.lastDragged) this.lastDragged.style.zIndex = '1000'
            if (this.lastDragged && this.lastDragged.classList.contains('big')) this.lastDragged.style.zIndex = '1000'
            this.draggedPoint = point.ref
            this.draggedPoint.index = index
            this.draggedPoint.style.zIndex = '1000'
            this.clicked = true
            this.mouseDownX = e.clientX
            this.mouseDownY = e.clientY
          })
        )
      })
      const moves = ['mousemove', 'touchmove']
      moves.forEach(cmd => {
        window.addEventListener(cmd, e => {
          e.preventDefault()
          if (this.draggedPoint) {
            this.UserMovedWithSomething = true
            const left = parseInt(this.draggedPoint.style.left)
            const top = parseInt(this.draggedPoint.style.top)
            this.draggedPoint.style.left = e.targetTouches
              ? `${e.targetTouches[0].pageX - 50}px`
              : `${left + e.movementX}px`
            this.draggedPoint.style.top = e.targetTouches
              ? `${e.targetTouches[0].pageY - 30}px`
              : `${top + e.movementY}px`
            if (this.draggedPoint.index === this.openedArticle) {
              this.setArticlePosition(this.draggedPoint.index)
              // this.setCollidingPoints(this.draggedPoint.index)
            }
            //this.draggedPoint.style.animation = `bounce 3s ease-in-out infinite forwards`
          }
        })
      })
      const ups = ['mouseup', 'touchend']
      ups.forEach(cmd => {
        window.addEventListener(cmd, () => {
          this.lastDragged = this.draggedPoint
          if (this.draggedPoint) this.draggedPoint.style.zIndex = null
          this.draggedPoint = null
        })
      })
    },

    setArticlePosition(index) {
      let colBox = this.articlePoints[index].colBox2
      if (!colBox) return

      const colBoxOffset = this.getOffset(colBox)

      if (colBoxOffset.top <= 30 && !this.articlePosition.includes('bottom'))
        this.articlePosition = `${this.articlePosition} bottom`
      else if (colBoxOffset.top > 30 && this.articlePosition.includes('bottom')) {
        this.articlePosition = this.articlePosition.replace(/bottom/g, '').trim()
      }
      if (colBoxOffset.top >= window.innerHeight - 300 && !this.articlePosition.includes('top'))
        this.articlePosition = `${this.articlePosition} top`
      else if (colBoxOffset.top < window.innerHeight - 300 && this.articlePosition.includes('top')) {
        this.articlePosition = this.articlePosition.replace(/top/g, '').trim()
      }
      if (colBoxOffset.left >= window.innerWidth - 300 && !this.articlePosition.includes('left'))
        this.articlePosition = `${this.articlePosition} left`
      else if (colBoxOffset.left < window.innerWidth - 300 && this.articlePosition.includes('left')) {
        this.articlePosition = this.articlePosition.replace(/left/g, '').trim()
      }
    },

    async toggleArticle(e, id) {
      if (this.player.id === id) {
        if (this.isDrag(e)) this.togglePlay()
      } else {
        if (Tone.context.state !== 'running') await Tone.start()
        this.playTone('8n', 'C', '5')

        if (this.isDrag(e)) {
          const handleListener = (event, cmd) => {
            if (
              !event.target.classList.contains('article-point') &&
              !event.target.classList.contains('open-button') &&
              !event.target.classList.contains('image') &&
              !event.target.classList.contains('article-picture') &&
              !event.target.classList.contains('fullscreen-image') &&
              !event.target.classList.contains('unicon') &&
              !event.target.parentNode.classList.contains('unicon') &&
              !event.target.classList.contains('play') &&
              !event.target.parentNode.classList.contains('play') &&
              !event.target.parentNode.classList.contains('checked-mark') &&
              !event.target.parentNode.classList.contains('mark') &&
              !event.target.classList.contains('generated-link')
            ) {
              this.openedArticle = ''
              document.removeEventListener(cmd, handleListener)
            }
          }
          if (this.openedArticle === id) {
            if (!e.target.classList.contains('generated-link')) this.openedArticle = ''
            document.removeEventListener('mousedown', handleListener)
            document.removeEventListener('touchend', handleListener)
          } else {
            const cmds = ['mousedown', 'touchend']
            cmds.forEach(cmd => {
              document.addEventListener(cmd, event => handleListener(event, cmd))
            })
            this.openedArticle = id
            this.checkedArticles.push(id)
          }
          this.setArticlePosition(id)
        }
      }
    },
    setPointRef(el, index) {
      try {
        if (el && !isNaN(index)) {
          this.articlePoints[index].ref = el
          this.articlePoints[index].colBox = el.children[0]
          this.articlePoints[index].colBox2 = el.children[1]
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
  async created() {
    try {
      this.articlePoints = await this.getArticles()
      const index = this.articlePoints.findIndex(point => point._id === this.$route.query.id)
      if (this.$route.query.id && index >= 0) {
        const mediaType = this.articlePoints[index].mediaType.split('/')[0]
        if (mediaType === 'image') this.openedArticle = index
        else if (mediaType === 'text') this.openText(this.articlePoints[index])
        else {
          this.getArticleFullscreen(index)
          this.disableIntro()
        }
      }
      setTimeout(() => {
        this.loaded = true
      }, 0)
    } catch (e) {
      console.log(e.message)
    }
    this.selectedElement = elements.find(el => el.link === this.$route.params.element)
    this.setElement(this.selectedElement)
  },
  watch: {
    loaded: function () {
      if (!this.loaded) return
      this.$nextTick(() => {
        this.processArticles()
      })
    },
  },
}
</script>

<style lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
html:active {
  cursor: none; /*changes to text-cursor*/
}
.home {
  //border: 15px solid var(--select-color);
  //box-shadow: inset 0 0 1em 0.2em rgba(0, 0, 0, 0.278);
  position: relative;
  padding: 0;
  transform-style: preserve-3d;
  min-width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  cursor: none !important;
  // max-height: 90vh;
  // max-width: 90vw;
  .points-box {
    transform-style: preserve-3d;
  }
  .home-loading {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    .unicon {
      svg {
        fill: var(--select-color);
        width: 7em;
        height: 7em;
      }
    }
  }
  .intro-text {
    user-select: none;
    top: 0;
    left: 15%;
    max-width: 70%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    //color: rgba(0, 0, 0, 0.445);
    font-size: 8em;
    font-family: 'Roboto', sans-serif;
    span {
      display: block;
      text-decoration: underline;
      text-underline-offset: 0.3em;
      text-decoration-thickness: 2px;
      margin-top: 0.5em;
      // margin-left: 0.5em;
      max-width: max-content;
      font-size: 0.3em;
      animation: 1s forwards appearOpacity;
      &.red {
        color: red;
      }
      &:hover {
        color: var(--text-color);
      }
    }
    .intro-text-final {
      opacity: 0;
      animation: 4s reverse appearOpacity;
    }
  }
}

.article-point {
  border: var(--point-border);
  opacity: 0;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.5em;
  height: 5.5em;
  background-color: var(--point-color);
  border-radius: 50%;
  z-index: 0;
  animation-name: bounce;
  animation-duration: 10000ms;
  animation-delay: 0ms;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  transition: width 0.2s, height 0.2s, border-radius 0.3s, transform 0.3s, background-color 2s, opacity 0.5s,
    border-width 2s;
  transform-style: preserve-3d;
  box-sizing: border-box;
  animation: none;

  //box-sizing: border-box;
  &.opacity-one {
    opacity: 1;
    user-select: initial;
    pointer-events: initial;
  }
  // &.checked-article {
  //   //border: 30px solid var(--select-color);
  // }
  .mark {
    display: flex;
    justify-content: center;
    align-items: center;
    .checked-mark {
      user-select: none;
      position: absolute;
      //border: 1px solid var(--select-color);
      // background-color: var(--select-color);
      font-size: 0.9em;
      transform: translateY(-0.5em) translateX(-0.16em);
      color: var(--select-color);
      border-radius: 50%;
      //font-weight: 700;
      z-index: -1;
    }
  }

  .collision-box {
    pointer-events: none;
    opacity: 0;
    z-index: 10;
    background-color: red;
    position: absolute;
    left: 10em;
    width: 20em;
    height: 20em;
  }
  .close-icon {
    opacity: 0;
    position: absolute;
    left: 1em;
    fill: var(--point-color);
  }
  &.element-point {
    z-index: 10;
    animation: blink 10s ease-in-out 0s infinite forwards !important;
    transition: width 1s, height 1s, border-radius 1s, transform 1s;
  }
  &.big-but-closed,
  &.big {
    width: 7em;
    height: 7em;
    transform: translateX(-10px) translateY(-10px);
    background-color: var(--select-color) !important;
    border-radius: 50%;
    animation: none !important;
    transition: width 1s, height 1s, transform 1s;
    z-index: 0;
    // .collision-box {
    //   opacity: 1;
    // }
    .close-icon {
      opacity: 1;
    }
    .article-full {
      opacity: 1;
      pointer-events: initial;
      transform: scaleY(1);
    }
  }
  &.big-but-closed {
    opacity: 0;
    .article-full {
      pointer-events: none !important;
    }
  }

  &.playing-point {
    width: 6.5em;
    height: 6.5em;
    background-color: var(--select-color);
    //border: 1px solid rgba(0, 0, 0, 0.5);
    //transition: all 0s;
    z-index: 1000000 !important;
    animation: none !important;

    transform: translateZ(3em);
    transition: box-shadow 0.6s;
    //box-shadow: 0em 0em 0.2em 0.1em rgba(0, 0, 0, 0.17);
    box-shadow: 0em 0em 0.5em 0.1em rgba(0, 0, 0, 0.08);
    //border: 1px solid black;
    .checked-mark {
      background-color: transparent;
    }

    &:hover {
      transition: box-shadow 0.2s;
      box-shadow: 0 0 0.5em 0.2em rgba(0, 0, 0, 0.162);
    }
    //box-shadow: 0 0 2em 0.2em rgba(0, 0, 0, 0.068);
    .playing-alt {
      //pointer-events: none;
      user-select: none;
      opacity: 0;
      font-family: 'Roboto', sans-serif;
      position: absolute;
      font-size: 0.9em;
      left: 4.2em;
      background-color: var(--select-color);
      padding: 0.7em 2em 0.7em 4em;
      min-width: max-content;
      z-index: 4000;
      color: black;
      border-radius: 0.4em;
      //border: 1px solid rgba(0, 0, 0, 0.7);

      transition: none;
      transform: translateZ(-0.1em);
      animation: appearOpacity 1s 0.5s forwards;
      box-shadow: 0em 0em 0.5em 0.1em rgba(0, 0, 0, 0.08);

      // &:hover {
      //   //opacity: 1;
      //   //pointer-events: initial;
      // }
        
      .alt-name {
        // text-decoration: underline;
        text-underline-offset: 0.1em;
        text-decoration-color: white;
        font-weight: 600;
        fill: black;
        color: var(--player-text-color);
        margin: 0.5em;
        &:hover {
          background-color: var(--player-text-color);
          color: var(--player-text-color);
          fill: white;
        }
      }
      .alt-progress-time {
        margin: 0.5em;
        font-weight: 400;
        font-size: 0.9em;
        color: var(--player-text-color);
        span {
          color: var(--player-text-color);
          font-weight: 600;
          margin: 0 1em;
        }
      }
      &.intro {
          .alt-name {
            &:hover {
              background-color: var(--select-color) !important;
              color: var(--player-text-color);
            }
         }
      }
    }
   
    box-sizing: border-box;
    .play-icon {
      margin-top: 0.25em;
      margin-left: 0.4em;
      fill: var(--player-text-color);
      transition: all 0.2s !important;
      .pause {
        margin-top: 0em;
        margin-left: -0.6em;
      }
      .spinner {
        margin-left: -0.6em;
      }
    }
  }
  .symbol {
    opacity: 1;
    transition: opacity 0.5s;
    margin: auto;
  }
  .main-button {
    opacity: 0;
    fill: var(--point-color);
    display: flex;
    justify-content: space-around;
    margin: 0.4em;
    width: 100%;
    transform: translateX(0.12em) translateY(0.15em) scale(0.5);
    transition: all 0.1s;
    p {
      font-size: 1.7em;
      margin: 0 0.1em;
      //color: darken(var(--select-color), 20);
    }
  }

  &:hover {
    background-color: var(--select-color);
    //box-shadow: 0 0 2em 1em rgba(0, 0, 0, 0.027);
    z-index: 1;
    animation: none !important;
    transition: all 0s;
    transition: width 1s, height 1s, transform 1s;
  }
  .top {
    top: -200%;
  }
  .bottom {
    top: 30%;
  }
  .left {
    left: -360%;
  }
}

@media only screen and (max-device-width: 812px) {
  .intro-text {
    margin-top: 2em;
    font-size: 3em !important;
    span {
      font-size: 0.8em !important;
    }
  }
  .article-full {
    user-select: none;
    top: 5em !important;
    width: 15em !important;
    min-height: 10em !important;
    font-size: 0.9em;
  }
  .article-point {
    width: 4em;
    height: 4em;
  }
  .playing-point {
    width: 6em !important;
    height: 6em !important;
  }
  .playing-alt {
    padding: 0.5em 3em !important;
  }
}

@keyframes appearScale {
  0% {
    transform: translateY(-0em) scale(0);
  }
  100% {
    transform: translateY(-0em) scale(1);
  }
}

@keyframes hover {
  0% {
    transform: translateY(-0em) scale(1);
  }
  25% {
    transform: translateY(-0.5em) scale(1);
  }
  50% {
    transform: translateY(-0em) scale(1);
  }
  75% {
    transform: translateY(-0.5em) scale(1);
  }
}

@keyframes appearOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    background-color: var(--point-color);
  }
  93% {
    background-color: var(--point-color);
  }
  95% {
    background-color: var(--select-color);
  }
  97% {
    background-color: var(--point-color);
  }
}

@keyframes activeBlink {
  0% {
    background-color: var(--select-color);
  }
  50% {
    background-color: var(--point-color);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
}

@keyframes spin {
  0% {
    transform: translateZ(-2em) rotate(0deg);
  }
  100% {
    transform: translateZ(-2em) rotate(360deg);
  }
}
</style>
