import { createStore } from 'vuex'
import axios from 'axios'

import introArticles from './introArticles'

export default createStore({
  state: {
    element: undefined,
    intro: {
      enabled: true,
      step: 0,
    },
    articles: [],
    articleWindow: false,
    player: {
      isLoading: true,
      mediaType: 'audio',
      playing: false,
      time: 0,
      loaded: 0,
      duration: 0,
      canplay: false,
      link: '',
      id: '',
      seekTo: 0,
      volume: 1,
      inFront: false,
      fullscreen: false,
    },
    isMobile: false,
    aboutOpened: false,
  },
  getters: {
    getPlayerProgressTime: state => {
      const time = `${Math.floor(state.player.time / 60)}:${
        Math.floor(state.player.time % 60) < 10
          ? `0${Math.floor(state.player.time % 60)}`
          : Math.floor(state.player.time % 60)
      }`
      const duration = `${Math.floor(state.player.duration / 60)}:${
        Math.floor(state.player.duration % 60) < 10
          ? `0${Math.floor(state.player.duration % 60)}`
          : Math.floor(state.player.duration % 60)
      }`
      return { time, duration }
    },
  },
  mutations: {
    setAboutOpened: (state, isOpened) => {
      state.aboutOpened = isOpened
    },
    setElement: (state, element) => {
      state.element = element
    },
    disableIntro: state => {
      state.intro.enabled = false
      state.intro.step = 10
    },
    setArticles: (state, articles) => {
      state.articles = articles
    },
    toggleArticleWindow: (state, isOpen) => {
      state.articleWindow = isOpen
    },
    toggleIntro: (state, enable) => {
      state.intro.enabled = enable
    },
    togglePlayerLoading: (state, isLoading) => {
      state.player.isLoading = isLoading
    },
    togglePlay: state => {
      state.player.playing = !state.player.playing
    },
    toggleFullscreen: state => {
      state.player.fullscreen = !state.player.fullscreen
    },
    setIntroMood: (state, mood) => {
      state.intro.mood = mood
    },
    nextIntroStep: state => {
      state.intro.step++
    },
    setPlayerID: (state, id) => {
      state.player.id = id
    },
    setPlayerLink: (state, link) => {
      state.player.link = link
    },
    setPlayerTime: (state, time) => {
      state.player.time = time
    },
    setPlayerLoaded: (state, loaded) => {
      state.player.loaded = loaded
    },
    setPlayerDuration: (state, duration) => {
      state.player.duration = duration
    },
    setPlayerMediaType: (state, mediaType) => {
      state.player.mediaType = mediaType
    },
    setPlayerVolume: (state, volume) => {
      state.player.volume = volume
    },
    seekTo: (state, time) => {
      state.player.seekTo = time
      state.player.time = time
    },
    playerToFront: (state, inFront) => {
      state.player.inFront = inFront
    },
    setCanPlay: (state, canplay) => {
      state.player.canplay = canplay
    },
    resetPlayer: state => {
      state.player = {
        isLoading: true,
        mediaType: '',
        playing: false,
        time: 0,
        duration: 0,
        link: '',
        canplay: false,
        id: '',
        volume: state.player.volume,
      }
    },
    setIsMobile: (state, isMobile) => {
      state.isMobile = isMobile
    },
  },
  actions: {
    getArticles: async () => {
      let articles = []

      try {
        const { data } = await axios.get('/api/articles')
        articles = data
      } catch (e) {
        console.log(e.message)
      }

      articles = articles.concat(introArticles)
      articles.forEach((article, index) => {
        article.id = index
      })
      return articles
    },
  },
  modules: {},
})
