import * as Tone from 'tone'

const numberOfSynths = 0
let currentSynth = 0

const gain = new Tone.Gain(0.5).toDestination()
const reverb = new Tone.Delay({ wet: 0.5, decay: 30 }).connect(gain)
const pingPong = new Tone.PingPongDelay({ delayTime: '8n', feedback: 0.4, wet: 0.1 }).connect(reverb)
const filter = new Tone.Filter(1200, 'highpass').connect(pingPong)
let synths = []

export const playTone = (length, root = 'C', octave = '4') => {
  const minorScale = Tone.Frequency(`${root}${octave}`).harmonize([0, 2, 3, 5, 7, 8, 10, 12])
  if (!synths[currentSynth]) synths[currentSynth] = new Tone.Synth().connect(filter)
  synths[currentSynth].oscillator.type = 'sine'
  const tone = Math.floor(Math.random() * 7)
  synths[currentSynth].triggerAttackRelease(minorScale[tone], length)

  currentSynth++
  if (currentSynth > numberOfSynths) currentSynth = 0
}
