<template>
  <svg
    @mouseenter="fill = hoverFill"
    @mouseleave="fill = 'none'"
    :width="width"
    :height="height"
    id="svg1872"
    sodipodi:version="0.32"
    inkscape:version="0.44.1"
    sodipodi:docbase="E:\Bryan\Temp"
    sodipodi:docname="Alchemy fire symbol.svg"
    viewBox="0 0 60 50"
  >
    <defs id="defs1874" />
    <g id="layer1" inkscape:label="Layer 1" inkscape:groupmode="layer">
      <path
        sodipodi:type="star"
        :style="{ fill, stroke }"
        style="
          opacity: 1;
          fill-opacity: 1;
          fill-rule: evenodd;
          stroke-width: 3;
          stroke-linecap: round;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        id="path1880"
        sodipodi:sides="3"
        sodipodi:cx="20.545456"
        sodipodi:cy="24.181818"
        sodipodi:r1="14.328495"
        sodipodi:r2="28.65699"
        sodipodi:arg1="0.69555062"
        sodipodi:arg2="1.7427482"
        inkscape:flatsided="false"
        inkscape:rounded="0"
        inkscape:randomized="0"
        d="M 31.545456,33.363636 L 15.64208,52.416194 L 7.0937687,29.117188 L -1.4545428,5.8181818 L 22.997144,10.06463 L 47.448831,14.311078 L 31.545456,33.363636 z "
        transform="matrix(0.640633,0.767847,-0.767847,0.640633,37.40911,7.893978)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'fire',
  props: ['width', 'height', 'hoverFill', 'stroke'],
  data() {
    return {
      fill: 'none',
    }
  },
}
</script>

<style></style>
