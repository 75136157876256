export default [
  {
    name: 'air',
    colorScheme: {
      pointColor: 'rgb(255, 0, 0)',
      selectColor: 'rgb(251, 255, 0)',
      pointBorder: 'none',
      textColor: 'black',
    },
    step1Sentence: 'Jak právě teď dýchate?',
    step2Sentence: 'zkuste na chvilku svůj dech zpomalit… ',
    link: 'air',
  },
  {
    name: 'earth',
    colorScheme: {
      pointColor: 'transparent',
      selectColor: 'black',
      pointBorder: '1px solid black',
      textColor: 'white',
    },
    step1Sentence: 'Co právě teď cítíte?',
    step2Sentence: 'je to v pořádku…',
    link: 'earth',
  },
  {
    name: 'water',
    colorScheme: {
      pointColor: 'blue',
      selectColor: 'rgb(251, 255, 0)',
      pointBorder: 'none',
      textColor: 'black',
    },
    step1Sentence: 'Co právě teď potřebujete?',
    step2Sentence: 'možná se vám bude líbit tohle… ',
    link: 'water',
  },
  {
    name: 'fire',
    colorScheme: {
      pointColor: 'rgb(52, 255, 52)',
      selectColor: 'rgb(251, 255, 0)',
      pointBorder: 'none',
      textColor: 'black',
    },
    step1Sentence: 'Měli jste na sebe dnes chvilku času?',
    step2Sentence: 'a co třeba hned teď… ',
    link: 'fire',
  },
]
