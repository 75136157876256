export default [
  {
    link: 'https://storage.googleapis.com/ufften.appspot.com/DEVELOPMENT-INTRO-ELEVETOR_MUSIC-1649965536744.mp3',
    name: 'elevator music',
    text: 'nezávazný background',
    intro: true,
    elements: ['water', 'air', 'earth'],
    introStepMin: 0,
    mediaType: 'audio/mpeg',
  },
  {
    link: 'https://storage.googleapis.com/ufften.appspot.com/DEVELOPMENT-INTRO-ELEVETOR_MUSIC-1649965536744.mp3',
    name: 'elevator music',
    text: 'už mě to sere (v průběhu to jde i vypnout)',
    intro: true,
    elements: ['fire'],
    introStepMin: 0,
    mediaType: 'audio/mpeg',
  },
  {
    link: 'https://storage.googleapis.com/ufften.appspot.com/DEVELOPMENT-ticho-1661258181782.mp3',
    name: 'ticho',
    text: 'to mi stačí',
    intro: true,
    elements: ['water', 'air', 'earth', 'fire'],
    introStepMin: 0,
    mediaType: 'audio/mpeg',
  },
  {
    link: 'https://storage.googleapis.com/ufften.appspot.com/DEVELOPMENT--1659223842199.mp3',
    name: 'zvuk přírody',
    text: 'pretty self explanatory',
    intro: true,
    elements: ['air'],
    introStepMin: 0,
    mediaType: 'audio/mpeg',
  },
  {
    link: 'https://storage.googleapis.com/ufften.appspot.com/-1657639694564.mp3',
    name: 'oheň',
    intro: true,
    text: 'pohoda při ohníčku',
    elements: ['fire'],
    introStepMin: 0,
    mediaType: 'audio/mpeg',
  },
  {
    link: 'https://storage.googleapis.com/ufften.appspot.com/-1657639840229.mp3',
    name: 'smích',
    intro: true,
    text: 'je to nakažlivé',
    elements: ['fire'],
    introStepMin: 0,
    mediaType: 'audio/mpeg',
  },
  {
    link: 'https://storage.googleapis.com/ufften.appspot.com/DEVELOPMENT-INTRO-CITY%20RUMBLE-1649965262799.wav',
    name: 'zvuk města',
    intro: true,
    text: 'NEPOTŘEBUJU ÚPLNÝ TICHO, ABYCH SE ZAPOSLOUCHAL SÁM DO SEBE!',
    elements: ['air'],
    introStepMin: 0,
    mediaType: 'audio/mpeg',
  },
  {
    link: 'https://storage.googleapis.com/ufften.appspot.com/DEVELOPMENT-INTRO-WATER_FLOW-1649965942379.wav',
    name: 'water flow',
    intro: true,
    text: 'chybí mi příroda / nejde mi čůrat / potřebuju se jen nadechnout',
    elements: ['water'],
    introStepMin: 0,
    mediaType: 'audio/mpeg',
  },
  {
    link: 'https://storage.googleapis.com/ufften.appspot.com/-1657639127846.mp3',
    name: 'pláč',
    intro: true,
    text: 'muži pláčou (v průběhu to jde i vypnout ) ',
    elements: ['water', 'earth'],
    introStepMin: 0,
    mediaType: 'audio/mpeg',
  },
  {
    link: 'https://storage.googleapis.com/ufften.appspot.com/-1657639389457.mp3',
    name: 'zvuk Země',
    intro: true,
    text: 'vychází to země',
    elements: ['earth'],
    introStepMin: 0,
    mediaType: 'audio/mpeg',
  },
  {
    link: 'https://storage.googleapis.com/ufften.appspot.com/DEVELOPMENT-INTRO-TANTRIC_MASCULINITY-1649966680464.mp3',
    name: 'tantric masculinity',
    intro: true,
    text: 'awaken male energy and sexuality mars frequency meditation music',
    elements: ['water'],
    introStepMin: 0,
    mediaType: 'audio/mpeg',
  },
  // {
  //   link: 'https://storage.googleapis.com/ufften.appspot.com/intro-1643061736181.jpg',
  //   intro: true,
  //   introStepMin: 1,
  //   mediaType: 'image/jpeg',
  // },
  // {
  //   link: 'https://storage.googleapis.com/ufften.appspot.com/intro-1643061710455.jpg',
  //   intro: true,
  //   introStepMin: 1,
  //   mediaType: 'image/jpeg',
  // },
  // {
  //   link: 'https://storage.googleapis.com/ufften.appspot.com/intro-1643061617383.com-gif-maker',
  //   intro: true,
  //   introStepMin: 1,
  //   mediaType: 'image/gif',
  // },
  // {
  //   link: 'https://storage.googleapis.com/ufften.appspot.com/intro-1643061604817.com-gif-maker (1)',
  //   intro: true,
  //   introStepMin: 1,
  //   mediaType: 'image/gif',
  // },
  {
    name: 'Otevřený dokument',
    text: 'V první fázi procesu vzniku jsme společně psali do jednoho google dokumentu, vy zde dostanete stejnou příležitost. Pište, cokoliv vás napadlo, reagujte na to, co už někdo napsal. Nikdy ale prosím nic nemažte a nepřepisujte, ale můžete např. něco dopsat do závorek.',
    elements: ['water', 'air', 'earth', 'fire'],
    intro: true,
    introStepMin: 1,
    mediaType: 'open/txt',
  },
]
