<template>
  <div :class="{ done: selected }" class="intro-window">
    <!-- <div class="UNDER_CONSTRUCTION">
      <p>Web projektu <span>za/tím neviditelná žena</span><br /><br />COMING SOON</p>
    </div> -->
    <div v-if="mobileWarning" class="mobile-warning">
      <p>Doporučujeme prohlížet tenhle web v klidu a na počítači</p>
      <div @click="mobileWarning = false" class="ok-button">Přesto pokračovat</div>
      <router-link :to="{ name: 'About' }" class="ok-button">O projektu</router-link>
    </div>
    <div class="intro-message">
      <p>{{ sentence1 }}</p>
      <p>{{ sentence2 }}</p>
    </div>
    <div class="elements" @mouseenter="hoverOnElement = true" @mouseleave="hoverOnElement = false">
      <router-link
        :to="{ name: 'Home', params: { element: elements.find(el => el.name === 'water').link } }"
        class="symbol-wrap"
      >
        <Water
          @click="startAudio()"
          ref="intro"
          class="symbol"
          :stroke="'blue'"
          :hoverFill="'blue'"
          :width="'4em'"
          :height="'4em'"
        />
      </router-link>
      <router-link
        :to="{ name: 'Home', params: { element: elements.find(el => el.name === 'air').link } }"
        class="symbol-wrap"
      >
        <Air
          @click="startAudio()"
          class="symbol"
          :stroke="symbolColor"
          :hoverFill="symbolColor"
          :width="'4em'"
          :height="'4em'"
        />
      </router-link>
      <router-link
        :to="{ name: 'Home', params: { element: elements.find(el => el.name === 'earth').link } }"
        class="symbol-wrap"
      >
        <Earth
          @click="startAudio()"
          class="symbol"
          :stroke="'black'"
          :hoverFill="'black'"
          :width="'4em'"
          :height="'4em'"
        />
      </router-link>
      <router-link
        :to="{ name: 'Home', params: { element: elements.find(el => el.name === 'fire').link } }"
        class="symbol-wrap"
      >
        <Fire
          @click="startAudio()"
          class="symbol"
          :stroke="'rgb(52, 255, 52)'"
          :hoverFill="'rgb(52, 255, 52)'"
          :width="'4em'"
          :height="'4em'"
        />
      </router-link>
    </div>

    <div class="intro-message headphones" :class="{ visible: hoverOnElement }">
      <p>Nasaďte sluchátka a nechte se chvilku unášet.</p>
    </div>
  </div>
</template>

<script>
import Air from '../svgs/air.vue'
import Earth from '../svgs/earth.vue'
import Fire from '../svgs/fire.vue'
import Water from '../svgs/water.vue'

import { mapMutations } from 'vuex'

import * as Tone from 'tone'

import elements from '../include/elements'

export default {
  components: {
    Air,
    Earth,
    Fire,
    Water,
  },
  data() {
    return {
      hoverColor: 'red',
      selected: '',
      symbolColor: 'red',
      selectedSymbolColor: 'red',
      elements,
      sentence1: ' ',
      sentence2: '',
      sentenceInterval: null,
      hoverOnElement: false,
      mobileWarning: true,
    }
  },
  methods: {
    ...mapMutations(['playerToFront']),
    async startAudio() {
      try {
        await Tone.start()
      } catch (e) {
        console.log(e)
      }
    },
  },
  mounted() {
    this.playerToFront(false)
    const sentence1 = ' Web divadelního projektu “WHOMAN””'
    const randomSentenceArr = [
      'Problém, co prý nemá jméno',
      'Můžou se i muži stát lidmi?',
      'Jsem muž? Co smím? Co nesmím? Co musím?',
      'Co ze mě může být vidět?',
      'Můžu to, co cítím uvnitř, cítit i navenek? ',
    ]
    const sentence2 = ' ' + randomSentenceArr[Math.floor(Math.random() * randomSentenceArr.length)]
    let blankArray = []
    for (let i = 0; i < sentence1.length; i++) {
      blankArray.push(' ')
    }
    this.sentence1 = blankArray.join('')

    for (let i = 0; i < sentence2.length; i++) {
      blankArray.push(' ')
    }
    this.sentence2 = blankArray.join('')

    let index = 0
    const usedIndexes = []

    const interval = setInterval(() => {
      while (usedIndexes.includes(index)) {
        index = Math.ceil(Math.random() * Math.max(sentence1.length, sentence2.length))
      }
      if (sentence1[index])
        this.sentence1 =
          this.sentence1.substring(0, index - 1) + sentence1[index] + this.sentence1.substring(index, sentence1.length)
      if (sentence2[index])
        this.sentence2 =
          this.sentence2.substring(0, index - 1) + sentence2[index] + this.sentence2.substring(index, sentence2.length)

      usedIndexes.push(index)
      // this.sentence2 += sentence2[index - sentence1.length] ? sentence2[index - sentence1.length] : ''
      if (usedIndexes.length === Math.max(sentence1.length, sentence2.length) + 1) {
        clearInterval(interval)
      }
    }, 45)
    this.sentenceInterval = interval
  },
  beforeUnmount() {
    clearInterval(this.sentenceInterval)
  },
}
</script>

<style lang="scss">
.intro-window {
  .UNDER_CONSTRUCTION {
    position: absolute;
    top: 0;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: white;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    p {
      font-family: 'Roboto', sans-serif;
      font-size: 3em;
      text-align: center;
      span {
        text-decoration: underline;
        text-decoration-color: yellow;
        text-underline-offset: 0.2em;
      }
    }
  }
  position: absolute;
  //background-color: white;
  opacity: 1;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: background-color 0.5s, opacity 0.5s;
  user-select: none;
  .mobile-warning {
    position: absolute;
    display: none;
    z-index: 100;
    flex-direction: column;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    background-color: rgba(22, 22, 22, 0.953);
    font-family: 'Robot', sans-serif;
    color: white;
    p {
      max-width: 80vw;
      font-size: 1.3em;
      margin: 3em 0;
      margin-top: 9em;
    }
    .ok-button {
      font-size: 1.2em;
      font-family: 'Robot', sans-serif;
      background-color: white;
      color: black;
      padding: 0.5em 1.5em;
      margin: 0.5em;
      text-decoration: none;
      min-width: 60vw;
    }
  }
  //background-color: black;
  .intro-message {
    position: absolute;
    top: 2em;
    max-width: 80vw;
    //display: none;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2em;
    //color: white;
    p {
      //display: inline;
      margin: 0.5em;
      padding: 0 2em;
      letter-spacing: 0.1em;
      font-size: 0.7em;
      //background-color: black;
      white-space: pre;
      span {
        font-size: 1em;
      }
    }
    &.headphones {
      opacity: 0;
      top: initial;
      bottom: 6em;
      font-size: 1em;
      background-color: black;
      color: white;
      max-height: min-content;
      border-radius: 0.4em;
      //transition: opacity 0.5s;
    }
    &.visible {
      opacity: 1;
    }
  }
  .elements {
    margin: 3em;
  }
  &.done {
    pointer-events: none;
    opacity: 0;
  }
}
.symbol-wrap {
  cursor: none;
  position: relative;
  .symbol {
    z-index: 5;
    margin: 2em;
    opacity: 1;
    transition: opacity 1.5s;
  }
}

@media only screen and (max-device-width: 812px) {
  .intro-window {
    .intro-message {
      padding-top: 3em;
      p {
        padding: 0em 0 !important;
        white-space: pre-wrap !important;
      }
    }
    .mobile-warning {
      display: flex !important;
    }
    .headphones {
      display: none !important;
    }
  }
}
</style>
