<template>
  <div @click="closeImage" class="fullscreen-image-window">
    <img :src="src" />
    <div class="cancel-icon">
      <unicon name="times" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['src'],
  emits: ['close-image'],
  methods: {
    closeImage() {
      this.$emit('close-image')
    },
  },
}
</script>

<style lang="scss">
.fullscreen-image-window {
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.928);
  z-index: 1000000;
  transform: translateZ(12em);
  display: flex;
  justify-content: center;
  align-items: center;
  .cancel-icon {
    position: absolute;
    top: 2em;
    right: 2em;
    svg {
      fill: var(--text-color);
      width: 4em;
      height: 4em;
    }
    &:hover {
      background-color: var(--text-color);
    }
  }
  img {
    height: 97%;
    width: 97%;
    object-fit: contain;
  }
}
</style>
