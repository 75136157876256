<template>
  <div class="about-page">
    <Menu v-if="$route.name === 'About'" />
    <div v-if="$route.name !== 'About'" @click="setAboutOpened(false)" class="cancel-icon">
      <unicon name="times" />
      <!-- <p>[esc]</p> -->
    </div>
    <div class="header">
      <h1>Za<span>/</span>tím neviditelná žena</h1>
    </div>
    <div class="first-part">
      <div class="anotace">
        <p>
          <span> Můžou se i muži stát lidmi?<br /><br /></span>

          Jsem muž?<br />
          Co smím? <br />
          Co nesmím?<br />
          Co musím?<br />
          Kdo mi to řekne? <br />
          Co ze mě může být vidět?<br />
          Můžu to co cítím uvnitř cítit i navenek?<br /><br />

          Zkoumáme stereotypy, normy a očekávání které jsme se naučili, které jsme si nechali vnutit, které jsme si
          vytvořili, které jsme si zautomatizovali a snažíme se je odlupovat. Hledáme, jak toto “odlupování” zpracovat
          jevištně. Ptáme se, jak komunikovat, abychom nikomu nic nevnucovali, ale zároveň dokázali vyjádřit to co
          cítíme. Svět kolem nás je takový, jaký si ho uděláme. Jsme za něj zodpovědní. Hledáme na jevišti svět, v němž
          by měly/i být vidět všechny/všichni takové/takoví, jaké/jací jsou i s jejich emocemi.<br />
          Jiří Šimek a Peter Šavel<br /><br />
        </p>
      </div>
      <div @click.prevent="fullscreenImage('/zatimneviditelnazena.webp')" class="title-image">
        <img src="/zatimneviditelnazena.webp" />
        <div v-if="$route.name !== 'About'" class="fullscreen-image">
          <unicon name="expand-arrows-alt" />
        </div>
        <p>Serie titulních fotografií projektu. Autorem je Marek Bartoš.</p>
      </div>
    </div>
    <div class="second-part">
      <div class="anotace">
        <p>
          Začali jsme četbou knihy Invisible Women: Exposing Data Bias in a World Designed for Men od autorky Criado
          Perez a brzy po ní přišla kniha The will to change - men, maskulinity and love od bell hooks. Dva roky jsme se
          zabývali nejrůznějšími tématy, která byla od průběhu covidu aktuální - uspořádání světa, s ohledem na „menší a
          slabé“, současná komunikace a její způsoby, představa o ideálu muže, svět jako místo, které je utvořeno jen
          pro některé z nás, emoční vyspělost… V průběhu celého procesu se budoucí inscenace neustále vyvíjela, protože
          se neustále vršily další podněty, např. mocenské kauzy na vysokých školách, zveřejněné případy zneužívání.
          Zůstal původní název a touha najít tvar, který bude nejlépe vypovídat o tom, o čem se často mlčí, protože je
          to považováno za trapné. O křehkosti, na niž mají právo i muži - ti ovšem tvoří menšinu divadelního publika.
          (Takže pokud nejste muži a dočetli jste až sem, zamyslete se nad tím, který muž z vašeho okolí by potřeboval
          trochu křehkosti, a vezměte ho s sebou do divadla!) Experimentujeme s hranicemi toho, co a jak dokážeme v
          divadle říct, a taky s tím, co ještě je divadlo. Neklademe si žánrové, formální ani výrazové hranice,
          divadelní tvar chápeme jako svobodné teritorium, kde je možné vše. Dokonce si i beztrestně zaplakat.<br /><br />
        </p>
      </div>
      <div class="text">
        <p>
          Performeři: Peter Šavel, Jiří Šimek <br />
          Dramaturgická a kreativní spolupráce: Marta Ljubková<br />
          Technický support a light design: Zuzana Režná a Štěpán Hejzlar <br />
          Produkce: Tereza Tomášová, Ludmila Vacková a Jiří Šimek<br />
          Web projektu: Matyáš Řezníček <br />
          Titulní fotografie projektu: Marek Bartoš<br /><br />

          Producenti: Studio ALTA (ALT@RT z.ú. ) a Ufftenživot<br />
          Koproducent: Moving Station Plzeň <br />
          Rezidenční pobyty (chronologicky): Velvarská kostka, Rezi.dance v lese, Sprang Scene /Äl, Norsko/, Baerum
          Kulturhus / Sandvika, Norsko /, Studio ALTA, Moving Station Plzeň <br />
          Podpořili: Magistrat hl.m. Prahy, MKCR, Státni fond kultury, EEA grants<br /><br />

          Inscenace WHOMAN je součástí projektu „Spolupráce kulturních domů: Studio ALTA, Baerum Kulturhus a Black Box Teater“, který je podpořen z finančních mechanismů EHP (EEA grants). <br />
        </p>
      </div>
    </div>
    <div class="third-part">
      <div @click.prevent="fullscreenImage('/jirkapetr.webp')" class="title-image">
        <img src="/jirkapetr.webp" />
        <div v-if="$route.name !== 'About'" class="fullscreen-image">
          <unicon name="expand-arrows-alt" />
        </div>
        <p>
          Jeden z nápadů na titulní fotografii projektu; nakonec jsme se jej rozhodli nevyužít. Fotil rovněž Marek
          Bartoš.
        </p>
      </div>
      <div class="authors">
        <p>
          <span>Jiří Šimek</span><br /><br />
          Věnuje se autorské tvorbě a je spoluzakladatelem souboru Ufftenživot. Absolvoval Katedru alternativního a
          loutkového divadla pražské DAMU v ateliéru herectví Petry Tejnorové. Jako herec/performer hostuje v
          nezávislých divadelních projektech především v Praze (Lachendebestien, Pomezí atd.). Účinkuje ve filmech a v
          televizi. Ve své tvorbě se zabývá harmonií fyzického divadla, tance, improvizace, slampoetry a nových médií,
          které dohromady spojuje téma projektu. Jeho poslední autorskou prací je sólová inscenace Words of Apology
          (2019) .V rámci Ufftenživot je spoluautorem úspěšných inscenací GoG, Loneliness and Stuff a KEEP CALM. Od roku
          2020 je také producentem a moderátorem podcastu Život on Air, který je platformou souboru Ufftenživot pro to,
          co se do divadla nevešlo.
        </p>
        <p>
          <span>Peter Šavel</span><br /><br />
          Choreograf, tanečník, pedagóg a life coach<br /><br />

          Peter ma bakalársky titul z VŠMU v Bratislave. Svoje štúdium ďalej rozšíril na Bruselskej medzinárodnej škole
          P.A.R.T.S. kde svoje štúdium zavŕšil v roku 2010. Od tej doby žije v Bruseli.<br />
          Od roku 2011 pracoval s francúzskym choreografom Pierre Droulers-om v Charleroi Danse - tancoval v
          reanimovanom “De l'air et du vent” a ako tanečník a choreografický asistent v “Soleils”.<br />
          V máji 2013 začal svoju spoluprácu s katalánskym choreografom Salvom Sanchisom a objavil sa v projektoch “The
          Phantom Layer", "The Organ Project", "Islands", a "Radical Light". <br />
          V roku 2016 sa stal tanečníkom v predstavení “Prototype” pod vedením Belgického choreografa Marc Vanrunxt-a,
          zakladateľa skupiny Kunst/Werk. Spolupracovali spolu aj na predstavení “Drawings” a tretej časti trilógie pod
          názvom “Pink&Orange” priemerovanej v roku 2021. Peter takisto kolaboruje s Kunst/Werk ako “umelecký satelit”.
          <br />
          Už roky úzko spolupracuje so Štúdiom Alta v Prahe, od roku 2019 v pozícii “umeleckého ambasádora”.<br />
          Ako choreograf, Peter vytvára a prezentuje svoje diela po celej Európe. Už niekoľkokrát boli vybrané pre sieť
          Aerowaves ako Top 20 - Boys who like to play with dolls s Terezou Ondrovou, Much more than nothing s MeSa,
          BAKKHEIA s Bod.Y… Najnovšie spolupracoval so Slovenským zoskupením mimoOs a vytvorili dielo TETSU - The Energy
          That Shapes Us.<br />
          Svoj tanečný workshop pod názvom “Letting go” vyučuje po celej Európe rovnako pre amatérov ako aj
          profesionálnych tanečníkov. Pravidelne vyučuje na Letnej škole v P.A.R.T.S. Mentoruje mnohé projekty mladých
          tvorcov a choreografov.<br />
        </p>
      </div>
      <div class="fourth-part"></div>
    </div>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
import { mapMutations } from 'vuex'
export default {
  components: {
    Menu,
  },
  emits: ['fullscreen-image'],
  methods: {
    ...mapMutations(['setAboutOpened']),
    fullscreenImage(src) {
      if (this.$route.name !== 'About') {
        this.$emit('fullscreen-image', src)
      }
    },
  },
}
</script>

<style lang="scss">
.about-page {
  transform-style: preserve-3d;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: scroll;
  transform: translateZ(11em);
  z-index: 10000;
  background-color: white;
  flex-shrink: 0;
  .header {
    margin: 5em;
    display: none;
    h1 {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      //text-decoration: underline;
      text-underline-offset: 0.6em;
    }
  }
  .cancel-icon {
    position: absolute;
    text-align: center;
    top: 2em;
    right: 2em;
    margin-bottom: 0.3em;
    animation: forwards 1s appear-opacity;
    transition: opacity 1s;

    svg {
      fill: black;
      transform: translateY(0.1em);
      width: 4em;
      height: 4em;
      //transition: none;
    }
    &.expand {
      svg {
        width: 1.6em;
        height: 1.6em;
      }
    }
    p {
      margin: 0.3em 0;
      width: 100%;
      font-size: 0.9em;
      text-align: center;
      font-family: 'Roboto', sans-serif;
      color: white;
    }
    &:hover {
      background-color: var(--text-color);
    }
  }
  .third-part,
  .second-part,
  .first-part,
  .fourth-part {
    min-height: 70vh;
    //margin-top: 4em;
    margin-bottom: 3em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 100vw;
    flex-wrap: wrap;
    .text,
    .authors,
    .anotace {
      margin: 0;
      font-family: 'Roboto', sans-serif;
      font-size: 0.9em;
      max-width: 40vw;
      min-width: 20em;
      margin: 2em;
      span {
        font-size: 4em;
        font-weight: 400;
      }
    }
    .authors {
      max-height: initial;
    }
    .text {
      font-style: italic;
    }
    .title-image {
      position: relative;
      max-width: 40vw;
      min-width: 20em;
      margin: 2em;

      p {
        font-family: 'Roboto', sans-serif;
        font-size: 0.9em;
        font-style: italic;
      }
      &:hover {
        .fullscreen-image {
          opacity: 1;
        }
      }
      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 1em;
        box-shadow: 0 0 1em 0.2em rgba(0, 0, 0, 0.152);
        border-radius: 0em;
      }
      .fullscreen-image {
        transition: opacity 0.2s;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        svg {
          width: 4em;
          height: 4em;
          fill: var(--text-color);
        }
      }
    }
  }
  .first-part {
    margin-top: 5em;
  }
  .fourth-part {
    min-height: 5vh;
  }
  .third-part {
    .title-image {
      padding: 0;
      min-width: initial;
      img {
        max-height: 100%;
      }
    }
  }
}

@media only screen and (max-device-width: 812px) {
  .about-page {
    max-width: 100vw !important;
  }
  .third-part,
  .second-part,
  .first-part {
    min-height: min-content !important;
    max-width: 100vw !important;
  }
  .title-image {
    min-width: 100vw !important;
  }
  .first-part {
    margin-top: 5em !important;
  }
  .third-part {
    margin-bottom: 4em !important;
  }
}
</style>
