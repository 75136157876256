<template>
  <div class="app">
    <router-view class="router" @mouse-over-point="isEnter => mouseOverPoint(isEnter)" />
    <div
      class="cursor"
      :class="{
        hidden: hideCursor,
        'intro-colors': intro.enabled,
        'earth-symbol': element && element.name === 'earth',
      }"
    >
      <div v-if="cursorType !== 'move'" :style="cursorPos" class="circle"></div>
      <div v-if="cursorType === 'default'" :style="pointPos" class="point"></div>
      <move-icon v-else-if="cursorType === 'move'" :width="'1.5em'" :height="'1.5em'" class="move" :style="pointPos" />
    </div>
  </div>
</template>

<script>
import moveIcon from './svgs/move-icon.vue'

import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      cursorX: 0,
      cursorY: 0,
      hideCursor: false,
      cursorType: 'default',
      selectedElement: null,
    }
  },
  components: {
    moveIcon,
  },
  methods: {
    ...mapMutations(['resetPlayer', 'togglePlay', 'setIsMobile']),
    moveCursor(e) {
      this.cursorX = e.clientX
      this.cursorY = e.clientY
    },
    mouseOverPoint(isEnter) {
      if (isEnter) this.cursorType = 'move'
      else this.cursorType = 'default'
    },
  },

  computed: {
    ...mapState(['player', 'intro', 'element']),
    cursorPos() {
      return `transform: translateX(${this.cursorX - 15}px) translateY(${this.cursorY - 10}px) translateZ(0em);`
    },
    pointPos() {
      return `transform: translateX(${this.cursorX - 5.8}px) translateY(${this.cursorY - 1}px) translateZ(0em);`
    },
  },
  mounted() {
    window.addEventListener('mousemove', this.moveCursor)
    window.addEventListener('mouseout', () => {
      this.hideCursor = true
    })
    window.addEventListener('mouseover', () => {
      this.hideCursor = false
    })
    // window.addEventListener('keydown', event => {
    //   if (event.key === ' ' && this.player.canplay) {
    //     this.togglePlay()
    //   }
    // })
    this.setIsMobile(window.matchMedia('only screen and (max-width: 812px)').matches)
  },
}
</script>

<style lang="scss">

body,
html {
  cursor: none;
  overflow: hidden;
  padding: 0;
  margin: 0;
  transform-style: preserve-3d;
  .app {
    height: 100vh;
    width: 100vw;
  }
}
html:active {
  cursor: none; /*changes to text-cursor*/
}
:root {
  --point-color: black;
  --select-color: rgb(251, 255, 0);
  --point-border: none;
  --default-color: rgb(0, 255, 200);
  --text-color: black;
  --player-text-color: black;
}

.cursor {
  transition: opacity 0.1s;
  z-index: 4000;
  &.earth-symbol {
    .point,
    .move {
      fill: white !important;
      border-color: white;
    }
  }

  &.hidden {
    opacity: 0;
  }

  &.intro-colors {
    .point {
      border: 2px solid var(--point-color);
    }
    .move {
      fill: white;
    }
  }
}
.point,
.circle {
  pointer-events: none;
  border: 2px solid var(--select-color);
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  z-index: 10000;
  transition: background-color 0.2s;
}
.circle {
  //position: relative;
  transition: transform 0.05s;
  z-index: 4000;
  background-color: var(--select-color);
}
.move {
  position: absolute;
  top: 0;
  pointer-events: none;
  margin-left: -0.5em;
  margin-top: -0.6em;
  fill: var(--point-color) !important;
  z-index: 4000;
}
.point {
  border: 2px solid var(--point-color);
  position: absolute;
  max-width: 0.4em;
  max-height: 0.4em;
  z-index: 4000;

  &.clicked {
    background-color: var(--select-color);
  }
}

@media only screen and (max-device-width: 812px) {
  // .router {
  //   display: none !important;
  // }
  .cursor {
    display: none;
  }
}
</style>
