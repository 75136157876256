<template>
  <svg viewBox="75 180 300 655" :height="height" :width="width" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 832h192V192H0V832zM320 192v640h192V192H320z" />
  </svg>
</template>

<script>
export default {
  name: 'pause-icon',
  props: ['width', 'height'],
}
</script>

<style></style>
