<template>
  <div class="article" :class="{ 'no-bg': hiddenTransition, 'white-bg': getMediaType(article.mediaType) === 'audio' }">
    <div class="button-wrapper absolute-button-wrapper">
      <div @click="closeArticle()" class="cancel-icon">
        <unicon name="times" />
        <!-- <p>[esc]</p> -->
      </div>
      <!-- <div @click="toggleVideoFullscreen()" v-if="hidden" class="cancel-icon expand">
        <unicon name="expand-arrows-alt" />
      </div> -->
    </div>
    <div
      class="audio-picture"
      v-if="getMediaType(article.mediaType) === 'audio' && article.audioFotoLink && !intro.enabled"
    >
      <img :src="article.audioFotoLink" />
    </div>
    <div
      @click.stop.self="_togglePlay()"
      @mouseleave="hideScreen(true)"
      @mousemove="hideScreen(false)"
      class="control-center"
    >
      <div
        @click="_togglePlay()"
        ref="playPoint"
        class="article-point player-point"
        :class="{ 'slide-down': hidden && !hiddenTransition }"
      >
        <div class="play-icon">
          <unicon :width="'2.3em'" :height="'2.3em'" v-if="player.isLoading" name="spinner" class="spinner" />
          <play-icon :width="'2.3em'" :height="'2.3em'" class="play" v-else-if="!player.playing" />
          <pause-icon :width="'2.3em'" :height="'2.3em'" class="pause" v-else />
        </div>
      </div>
      <div class="control-bar" :class="{ 'opacity-zero': hiddenTransition, 'slide-down': hidden }">
        <div class="progress">
          <div class="progress-bar">
            <div class="duration-time">
              {{ getPlayerProgressTime.duration }}
            </div>
            <input
              @change="e => _seekTo(e.target.value)"
              class="sleek-bar"
              type="range"
              :max="Math.floor(player.duration)"
              :value="Math.floor(player.time)"
            />
            <div
              :style="{
                width: `${(player.time / player.duration) * 100}%`,
              }"
              :class="{ 'no-transition': !progressBarTransition }"
              class="inner-bar"
            >
              <div class="progress-time" :class="{ hidden: (player.time / player.duration) * 100 > 87 }">
                {{ getPlayerProgressTime.time }}
              </div>
            </div>
            <div
              :style="{
                width: `${(player.loaded / player.duration) * 100}%`,
              }"
              :class="{ 'no-transition': !progressBarTransition }"
              class="inner-bar loaded-bar"
            ></div>
          </div>
        </div>
        <div class="text-wrapper">
          <div class="button-wrapper">
            <div ref="volumePoint" class="control-button">
              <div @click="player.volume === 0 ? setPlayerVolume(1) : setPlayerVolume(0)" class="play-icon">
                <unicon v-if="player.volume === 0" class="volume" name="volume-mute" />
                <unicon v-else class="volume" name="volume-up" />
              </div>
            </div>
            <a target="_blank" :href="article.youtubeLink" v-if="article.youtubeLink" class="control-button">
              <div class="play-icon youtube-icon">
                <youtube :width="'1.4em'" :height="'1.4em'" :fill="'red'" />
              </div>
            </a>
            <a target="_blank" :href="article.spotifyLink" v-if="article.spotifyLink" class="control-button">
              <div class="play-icon youtube-icon">
                <spotify :width="'1.4em'" :height="'1.4em'" :fill="'red'" />
              </div>
            </a>
            <a target="_blank" :href="article.simplecastLink" v-if="article.simplecastLink" class="control-button">
              <div class="play-icon youtube-icon">
                <simplecast :width="'1.4em'" :height="'1.4em'" :fill="'white'" />
              </div>
            </a>
            <div class="control-button empty-control-button"></div>
          </div>
          <div :class="{ 'opacity-zero': hiddenTransition }" class="text">
            <h1><span></span>{{ article.name }}</h1>
            <!-- <p class="type">{{ article.mediaType.split('/')[0] }}</p> -->
            <p>
              <span v-for="(textObject, index) in formatText" :key="index" class="text generated-link"
                >{{ textObject.text }}
                <a class="generated-link" :href="`https://` + textObject.link" target="_blank">{{
                  textObject.link
                }}</a></span
              >
            </p>
          </div>
          <div class="button-wrapper">
            <div @click="closeArticle()" class="control-button" :class="{ 'intro-colors': intro.enabled }">
              <unicon class="volume" name="compress-alt-left" />
            </div>
            <div v-if="getMediaType(article.mediaType) === 'video'" class="control-button">
              <div @click="toggleVideoFullscreen()" class="play-icon">
                <unicon class="volume" name="expand-arrows-alt" />
              </div>
            </div>
            <div class="control-button empty-control-button"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'

import playIcon from '../svgs/play2.vue'
import pauseIcon from '../svgs/pause.vue'
import youtube from '../svgs/youtube.vue'
import spotify from '../svgs/spotify.vue'
import simplecast from '../svgs/simplecast.vue'

import { getMediaType } from '../utils/mimeFormat'
import { limitWords } from '../utils/text'

export default {
  name: 'Article',
  data() {
    return {
      hiddenTransition: false,
      hidden: false,
      progressBarTransition: true,
      hideTimeout: null,
      autoclose: false,
    }
  },
  components: {
    playIcon,
    pauseIcon,
    youtube,
    spotify,
    simplecast,
  },
  props: ['article'],
  emits: ['close-article'],
  computed: {
    ...mapState(['player', 'intro']),
    ...mapGetters(['getPlayerProgressTime']),
    formatText() {
      let text = limitWords(this.article.text, 350)
      if (text.length < this.article.text.length) {
        text = text + ' ...'
      }
      let textObjectArray = []
      let lastIndex = 0
      for (;;) {
        let index = text.indexOf('https://', lastIndex + text.slice(index).split(' ')[0].length)
        if (index == -1) {
          textObjectArray.push({
            text: text.slice(lastIndex != 0 ? lastIndex + text.slice(lastIndex).split(' ')[0].length : 0),
            index,
          })
          break
        }
        textObjectArray.push({
          text: text.slice(lastIndex != 0 ? lastIndex + text.slice(lastIndex).split(' ')[0].length : 0, index),
          link: text.slice(index).split(' ')[0].split('https://')[1],
        })
        lastIndex = index
      }
      return textObjectArray
    },
  },
  methods: {
    ...mapMutations([
      'togglePlay',
      'seekTo',
      'setPlayerLink',
      'setPlayerID',
      'setPlayerMediaType',
      'resetPlayer',
      'setPlayerVolume',
      'playerToFront',
      'toggleFullscreen',
    ]),
    _togglePlay() {
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout)
        this.hideTimeout = null
      }
      this.togglePlay()
    },
    _seekTo(value) {
      this.seekTo(value)
      this.progressBarTransition = false
      setTimeout(() => {
        this.progressBarTransition = true
      }, 10)
    },
    toggleVideoFullscreen() {
      this.toggleFullscreen()
    },
    limitWords(text, limit) {
      return limitWords(text, limit)
    },
    hideScreen(isHidden) {
      if (this.getMediaType(this.article.mediaType) === 'video' && this.player.canplay && this.player.playing) {
        if (!isHidden) {
          this.hidden = false
          if (this.hideTimeout) {
            clearTimeout(this.hideTimeout)
            this.hideTimeout = null
          }
          this.hideTimeout = setTimeout(
            () => {
              this.hidden = true
            },
            6000,
            { once: true }
          )
        } else {
          this.hidden = true
        }
      }
    },
    getMediaType(mimeType) {
      return getMediaType(mimeType)
    },
    closeArticle() {
      this.playerToFront(false)
      this.$emit('close-article')
    },
  },
  created() {
    if (this.article.id !== this.player.id) {
      this.resetPlayer()
      this.autoclose = true
    }
  },
  mounted() {
    if (this.article) {
      this.playerToFront(true)
      this.setPlayerMediaType(getMediaType(this.article.mediaType))
      this.setPlayerID(this.article.id)
      this.setPlayerLink(this.article.link)
      if (this.article.intro || this.autoclose) this.closeArticle()
    }
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        e.preventDefault()
        this.closeArticle()
      }
    })
  },
}
</script>

<style lang="scss">
:root {
  --black: rgb(23, 23, 23); // rgb(29, 29, 29);
  --shadow: 0 0 0.8em 0.1em rgba(0, 0, 0, 0.486);
}
.article {
  transform-style: preserve-3d;
  position: absolute;
  pointer-events: initial;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px solid var(--text-color);
  box-sizing: border-box;
  // background: linear-gradient(
  //   90deg,
  //   rgba(0, 0, 0, 0.9) 0%,
  //   rgba(0, 0, 0, 1) 30%,
  //   rgba(0, 0, 0, 1) 70%,
  //   rgba(0, 0, 0, 0.9) 100%
  // );
  background-color: rgba(0, 0, 0, 0.928);
  //backdrop-filter: blur(5px);
  //animation: 0.6s forwards appear-white;
  transition: opacity 1s background-color 1s;
  .absolute-button-wrapper {
    position: absolute;
    top: 1.2em;
    right: 1.2em;
  }
  &.white-bg {
    .control-center {
      width: 800px;
      .control-bar {
        //border: 0.5em solid black;
        // border-radius: 1.5em;
      }
    }
  }
  .audio-picture {
    text-align: center;
    position: absolute;
    background-color: white;
    top: 2.2em;
    //width: 60vw;
    width: 400px;
    overflow: hidden;
    //box-shadow: 0 0 2em 0.2em rgba(0, 0, 0, 0.164);
    //border: 0.5em solid black;
    //background-color: red;
    border-radius: 1em;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain !important;
    }
  }
  &.opacity-zero {
    //pointer-events: none;
    transition: opacity 0.8s !important;
    opacity: 0 !important;
  }
  &.no-bg {
    background-color: transparent !important;
    pointer-events: none;
  }
  .cancel-icon {
    //position: absolute;
    text-align: center;
    //background-color: black;
    //padding: 0.4em;
    margin-bottom: 0.3em;
    //border: 2px solid black;
    animation: forwards 1s appear-opacity;
    transition: opacity 1s;
    transform: translateZ(2em);
    svg {
      fill: var(--text-color);
      transform: translateY(0.1em);
      width: 4em;
      height: 4em;
      //transition: none;
    }
    &.expand {
      svg {
        width: 1.6em;
        height: 1.6em;
      }
    }
    p {
      margin: 0.3em 0;
      width: 100%;
      font-size: 0.9em;
      text-align: center;
      font-family: 'Roboto', sans-serif;
      color: white;
    }
    &:hover {
      background-color: var(--text-color);
    }
  }
  .control-center {
    height: 100vh;
    width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    transform-style: preserve-3d;
    transform: translateZ(2em);
    .control-bar {
      box-shadow: var(--shadow);
      border-radius: 0.1em;
      transition: all 0.3s;
      padding: 0em;
      min-width: 100%;
      margin-bottom: 1.5em;
      padding-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      background-color: black; // var(--black);
      //border-top: none;
      //box-shadow: 0 0 0.5em 0.2em rgba(0, 0, 0, 0.164);
      box-sizing: border-box;
      overflow: hidden;
      z-index: 100;

      .text-wrapper {
        //min-width: min-content;
        display: flex;

        width: 100%;
      }
      .control-button {
        //background-color: black;
        fill: white;
        display: flex;
        justify-content: center;
        //box-shadow: var(--shadow);
        align-items: center;
        margin: 0em;
        min-width: 3em;
        height: 3em;
        background-color: black; //var(--black);
        cursor: none;
        .youtube-icon {
          margin-top: 0.3em !important;
        }
        &.empty-control-button {
          display: none;

          &:hover {
            background-color: black;
          }
        }
        .volume {
          width: 1.4em;
          height: 1.4em;
          transform: translateY(0.15em);
        }
        &:hover {
          background-color: var(--text-color);
        }
      }
      &.opacity-zero {
        //pointer-events: none;
        transition: opacity 0.8s !important;
        opacity: 0 !important;
      }
      &.slide-down {
        transform: translateY(40em);
        transition: transform 2s;
      }
    }
  }
  .text {
    user-select: none;
    position: relative;
    font-family: 'Roboto', sans-serif;
    width: 100%;
    //margin: 2em;
    //border: 1px solid var(--select-color);
    background-color: rgb(0, 0, 0);
    background-color: var(--black);
    //box-shadow: var(--shadow);
    padding: 1.3em 2em;
    margin-top: 0em;
    //min-height: 8em;
    //background-color: var(--point-color);
    animation: 1s forwards appear-opacity;
    transition: opacity 0.2s !important;
    z-index: 5000 !important;
    color: rgb(255, 255, 255);
    border: 2px solid black;
    box-sizing: border-box;
    h1 {
      margin: 0;
      margin-bottom: 1em;
      text-decoration: underline;
      text-underline-offset: 0.3em;
      text-decoration-color: var(--text-color);
      font-size: 1em;
      font-weight: 400;
      // text-decoration: underline;
      // text-decoration-color: var(--select-color);
      // text-underline-offset: 0.2em;
      text-decoration-thickness: 1px;
    }
    p {
      padding: 0;
      margin: 0em 0;
      font-size: 0.9em;
      color: rgb(238, 238, 238);
      span {
        border: none;
        margin: 0;
        padding: 0;
        a {
          color: var(--text-color);
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    .type {
      text-transform: uppercase;
      color: var(--text-color);
      font-size: 0.7em;
      margin: 0.6em 0;
      //background-color: black;
      max-width: fit-content;
      //padding: 0.3em;
    }
  }
  .player-point {
    margin: 3em;
    position: initial;
    animation: none;
    opacity: 1;
    pointer-events: initial;
    //border: 1px solid var(--select-color);
    box-shadow: 0 0 1em 0.2em rgba(0, 0, 0, 0.317);
    background-color: var(--text-color);
    border: 0px solid black;
    transition: box-shadow 0.3s, top 0.6s, left 0.6s, background-color 0.3s, transform 0.3s;
    z-index: 100;
    width: 6em;
    height: 6em;
    box-sizing: border-box;
    transform: translateY(0em) translateZ(2em);
    //border-radius: 0;
    //animation: 1s forwards color-change;
    &.slide-down {
      transform: translateY(30em) translateZ(2em);
      transition: transform 0.7s;
    }
    //box-sizing: border-box;
    .play-icon {
      margin-top: 0.3em;
      margin-left: 0.5em;
      fill: black;
      animation: 0.4s forwards appear-opacity;
      .spinner {
        animation: 1s forwards infinite spin;
        margin-left: -0.4em;
      }
      .pause {
        margin-left: -0.6em;
      }
    }

    &:hover {
      transition: background-color 0.2s;
      box-shadow: none;
      background-color: black;
      //border: 2px solid var(--select-color);
    }
  }
  .progress {
    animation: forwards 1s appear-opacity;
    width: 100%;

    .progress-bar {
      margin: 0em;
      margin-bottom: 0em;
      position: relative;
      height: 0.4em;
      //border: 1px solid var(--select-color);
      box-sizing: border-box;
      //outline: 1px solid rgb(0, 0, 0);
      background-color: black; //var(--black);
      //box-shadow: var(--shadow);
      transition: height 0.4s;
      .sleek-bar {
        cursor: none;
        opacity: 0;
        appearance: none;
        position: relative;
        top: -0.6em;
        left: -0.4em;
        width: 103%;
        height: 2.1em;
        z-index: 10;
      }
      .inner-bar {
        top: 0;
        //border: 7px solid var(--point-color);
        border-right: 7px solid var(--text-color);
        position: absolute;
        background-color: var(--select-color);
        height: 100%;
        transition: width 0.5s linear, background-color 0.5s, border-width 0.5s;
        box-sizing: border-box;
        z-index: 1;
        &.no-transition {
          transition: all 0s;
        }
      }

      .loaded-bar {
        background-color: rgb(19, 19, 19);
        border: none;
        z-index: 0;
        transition: none;
      }
      .duration-time {
        position: absolute;
        color: white;
        z-index: 100;
        right: 0.9em;
      }
      &:hover {
        height: 1.5em;
        .duration-time,
        .progress-time {
          opacity: 1;
        }
        .inner-bar {
          background-color: var(--text-color);
          border-width: 0px;
        }
        .loaded-bar {
          background-color: rgb(19, 19, 19);
        }
      }
    }
    .duration-time,
    .progress-time {
      opacity: 0;
      user-select: none;
      //display: none;
      position: absolute;
      right: -3.5em;
      top: 2.5px;
      font-family: 'Roboto', sans-serif;
      text-align: right;
      font-size: 0.8em;
      font-weight: 400;
      color: rgb(255, 255, 255);
      transition: opacity 0.2s;
      //color: var(--default-color);
      span {
        margin: 0 1em;
        font-size: 1.5em;
        font-weight: 700;
        color: var(--select-color);
      }
      &.hidden {
        opacity: 0 !important;
      }
    }
  }
}

@media only screen and (max-device-width: 812px) {
  .control-center {
    height: 70vh !important;
  }
}

@keyframes appear-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear-white {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.9);
  }
}

@keyframes color-change {
  0% {
    background-color: rgb(0, 0, 0);
  }
  100% {
    background-color: var(--select-color);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
