<template>
  <div
    :class="{
      top: articlePosition.includes('top') && opened,
      bottom: articlePosition.includes('bottom') && opened,
      left: articlePosition.includes('left') && opened,
      mobile: articlePosition.includes('mobile') && opened,
    }"
    class="article-full"
  >
    <h1><span></span>{{ point.name }}</h1>
    <div v-if="mediaType(point.mediaType) === 'image'" @click="e => fullscreenImage(point.link)" class="image">
      <img class="article-picture" :src="point.link" />
      <div class="fullscreen-image">
        <unicon name="expand-arrows-alt" />
      </div>
    </div>
    <p v-if="mediaType(point.mediaType) === 'image'" class="picture-likes">
      <span>LIKES: </span>{{ Math.ceil(Math.random() * 1000000) }}
    </p>
    <p class="type">
      {{ point.mediaType.split('/')[0] }}
    </p>
    <p>
      <span v-for="(textObject, index) in formatText" :key="index" class="text generated-link"
        >{{ textObject.text }}
        <a class="generated-link" :href="`https://` + textObject.link" target="_blank">{{ textObject.link }}</a></span
      >
    </p>
    <div @click="pointClick(point)" class="open-button">
      <!-- <unicon class="like" v-if="mediaType(point.mediaType) === 'image'" name="thumbs-up" /> -->
      <heart-icon
        :width="'2em'"
        :height="'2em'"
        class="play"
        :hoverFill="'black'"
        :stroke="'black'"
        v-if="mediaType(point.mediaType) === 'image'"
      />
      <div v-else-if="mediaType(point.mediaType) === 'text'" :width="'2em'" :height="'2em'" class="play"></div>
      <div v-else-if="mediaType(point.mediaType) === 'open'" :width="'2em'" :height="'2em'" class="play"></div>
      <play-icon v-else :width="'2em'" :height="'2em'" class="play" />
      {{ pointButtonSign(point.mediaType) }}
    </div>
  </div>
</template>

<script>
import { getMediaType } from '../utils/mimeFormat'
import { limitWords } from '../utils/text'
import heartIcon from '../svgs/heart.vue'
import playIcon from '../svgs/play2.vue'
export default {
  emits: ['point-click', 'fullscreen-image'],
  props: ['point', 'articlePosition', 'opened'],
  computed: {
    formatText() {
      let text = limitWords(this.point.text, 350)
      if (text.length < this.point.text.length) {
        text = text + ' ...'
      }
      let textObjectArray = []
      let lastIndex = 0
      for (;;) {
        let index = text.indexOf('https://', lastIndex + text.slice(index).split(' ')[0].length)
        if (index == -1) {
          textObjectArray.push({
            text: text.slice(lastIndex != 0 ? lastIndex + text.slice(lastIndex).split(' ')[0].length : 0),
            index,
          })
          break
        }
        textObjectArray.push({
          text: text.slice(lastIndex != 0 ? lastIndex + text.slice(lastIndex).split(' ')[0].length : 0, index),
          link: text.slice(index).split(' ')[0].split('https://')[1],
        })
        lastIndex = index
      }
      return textObjectArray
    },
  },
  methods: {
    mediaType(mimeType) {
      return getMediaType(mimeType)
    },
    pointButtonSign(mimeType) {
      switch (getMediaType(mimeType)) {
        case 'image':
          return 'LIKE THIS ONE'
        case 'text':
          return 'READ'
        case 'video':
        case 'audio':
          return 'PLAY'
        case 'open':
          return 'PIŠTE'
      }
    },
    pointClick(index, point) {
      this.$emit('point-click', index, point)
    },
    fullscreenImage(img) {
      this.$emit('fullscreen-image', img)
    },
  },
  components: {
    heartIcon,
    playIcon,
  },
}
</script>

<style lang="scss">
.article-full {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: -120%;
  left: 100%;
  width: 20em;
  min-height: 12em;
  letter-spacing: 0.02em;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 0.4em 0.1em rgba(0, 0, 0, 0.06);
  transform: translateZ(10em) !important;
  //border: 1px solid black;
  padding: 1em 1.5em;
  margin: 1em;
  user-select: none;
  font-family: 'Roboto', sans-serif;
  //transition: all 0.2s;
  text-align: left;
  transition-property: left, top;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  border-radius: 0.3em;
  .image {
    position: relative;

    img {
      max-height: 20em;
      width: 100%;
      object-fit: contain;
    }
    .fullscreen-image {
      opacity: 0;
      top: 50%;
      right: 50%;
      position: absolute;
      transform: translate(50%, -50%);
      transition: opacity 0.2s;
      svg {
        width: 2em;
        height: 2em;
        fill: var(--text-color);
      }
    }
    &:hover {
      .fullscreen-image {
        opacity: 1;
        transition: opacity 0.2s;
      }
    }
  }

  .picture-likes {
    span {
      font-weight: 700;
    }
  }
  &.top {
    top: -250%;
  }
  &.bottom {
    top: 0%;
  }
  &.left {
    left: -350%;
  }
  &.mobile {
    position: fixed;
    left: 50%;
    transform: translateX(-54%) translateZ(10em) !important;
    top: 10em;
    width: 80% !important;
    opacity: 1;
    pointer-events: initial;
    transform: scaleY(1);
  }
  //border: 3px solid black;
  h1 {
    margin: 0.7em 0;
    font-size: 1.2em;
    font-weight: 600;
    //text-decoration: underline;
    text-underline-offset: 0.2em;
    text-decoration-color: black;
    color: black;
    line-height: 1.2em;
  }
  .type {
    //display: none;
    text-transform: uppercase;
    color: var(--select-color);
    background-color: black;
    max-width: fit-content;
    padding: 0.3em;
    font-size: 0.8em;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    color: var(--text-color);
  }
  .text {
    margin: 0.7em 0;
    font-weight: 400;
    font-size: 0.9em;
    color: black;
    a {
      color: blue;
      text-decoration: none;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .open-button {
    //text-transform: uppercase;
    //border: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: black;
    margin: 1em 0;
    //box-shadow: 0 0 1em 0.1em rgba(0, 0, 0, 0.082);
    //border-radius: 0.2em;
    background-color: white;
    text-align: center;
    padding: 0.5em;
    transition: all 0.2s;
    font-weight: 600;
    box-shadow: 0 0 0.2em 0.1em rgba(0, 0, 0, 0.06);
    .play {
      margin: 0.1em 1.5em 0.1em 1em;
      height: 1.4em;
      fill: var(--select-color);
      transition: all 0.2s;
    }
    .like {
      fill: var(--select-color);
      margin: 0 1em;
    }
    &:hover {
      background-color: var(--select-color);
      box-shadow: none;
      box-shadow: 0 0 0.7em 0.1em rgba(0, 0, 0, 0.06);
      // border: 1px solid var(--select-color);
      // border: 1px solid grey;
      // .play {
      //   fill: white;
      //   transition: all 0.2s;
      // }
      //color: white;
    }
  }
}

@media only screen and (max-device-width: 812px) {
  .article-full {
    transform: translateZ(2em) !important;
  }
  .image {
    .fullscreen-image {
      display: none !important;
    }
  }
}
</style>
